<template>
    <div class="registerView">
        <div class="RegisterContainer">
            <div class="RegisterContent">
                <h1 style="text-align: center;">Bouncy Vibes Radio</h1>
            </div>
            <div class="register-form">
                <h2>Register</h2>
                <v-form @submit.prevent="registerUser" ref="registerForm">
                    <v-container>
                        <v-row>
                            <!-- Username Input -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="username"
                                    label="Username"
                                    :rules="[usernameRule]"
                                    required
                                    placeholder="Enter Username"
                                ></v-text-field>
                            </v-col>

                            <!-- Email Input -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="email"
                                    label="Email"
                                    type="email"
                                    :rules="[emailRule]"
                                    required
                                    placeholder="Enter Email"
                                ></v-text-field>
                            </v-col>

                            <!-- Password Input -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="password"
                                    label="Password"
                                    type="password"
                                    :rules="[passwordRule]"
                                    required
                                    placeholder="Enter Password"
                                ></v-text-field>
                            </v-col>

                            <!-- Confirm Password Input -->
                            <v-col cols="12">
                                <v-text-field
                                    v-model="confirmPassword"
                                    label="Confirm Password"
                                    type="password"
                                    :rules="[passwordRule]"
                                    required
                                    placeholder="Confirm Password"
                                ></v-text-field>
                            </v-col>

                            <!-- Register Response Alert -->
                            <v-col cols="12">
                                <v-alert v-if="RegisterResponse" type="info">
                                    {{ RegisterResponse }}
                                </v-alert>
                            </v-col>

                            <!-- Submit Button and Link to Login -->
                            <v-row justify="center">
                                <v-btn type="submit" color="primary">Register</v-btn>
                                <v-divider></v-divider>
                                <v-chip color="blue" class="white--text" @click="this.$router.push('/login')">Login</v-chip>
                            </v-row>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
        </div>
    </div>
</template>

<script>
import authService from '../services/authService';

export default {
    data() {
        return {
            username: '',
            password: '',
            email: '',
            confirmPassword: '',
            RegisterResponse: '',
            usernameRule: value => /^[a-zA-Z0-9_]+$/.test(value) || 'Username must be a single string without spaces or special characters.',
            passwordRule: value => value.length >= 8 || 'Password must be at least 8 characters long.',
            emailRule: value => /.+@.+\..+/.test(value) || 'E-mail must be valid.'
        };
    },
    methods: {
        registerUser() {
            const isValid = this.$refs.registerForm.validate();

            if (!isValid) {
                this.RegisterResponse = "Please fill out all required fields.";
                return;
            }

            if (this.password !== this.confirmPassword) {
                this.RegisterResponse = 'Passwords do not match.';
                return;
            }

            authService.register(this.username, this.password, this.email)
                .then(response => {
                    if (response.token) {
                        this.$router.push('/login');
                    }
                })
                .catch(error => {
                    this.RegisterResponse = error.response.data.msg || "Registration failed.";
                });
        }
    },
    mounted() {}
};
</script>

<style scoped>
.registerView {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #424242;
    background-image: url(https://bouncy-vibes.co.uk/images/bg.jpg) !important;
    background-attachment: fixed !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;
}

.form-group {
    padding: 5px;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.register-form {
    background-color: #191B1A;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 380px;
    margin: 0 auto;
    height: fit-content;
    border-radius: 40px 0px 40px 0px;
    color: white;
}

.RegisterContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.RegisterContent {
    background-color: #f2f2f2;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: 0 auto;
    background-image: url('../assets/art1.jpg');
    background-size: cover;
    background-position: center;
    height: 280px;
    width: 100%;
    color: white;
    text-shadow: 2px 2px 4px #000000;
    border-radius: 25px 0px 0px 25px;
}
</style>
