<template>
  <div class="feed">
    <h1>Latest Posts</h1>
    <!-- Add a text field for the user to post -->
    <v-text-field v-model="postText" label="Post something..." outlined clearable
      @keyup.enter="postContent()"></v-text-field>
    <!-- Loop through the posts array and display each post -->
    <div class="d-flex flex-column">
      <v-card v-for="post in posts" :key="post._id" class="post">
        <!-- Post header with avatar and username -->
        <v-card-title class="d-flex justify-space-between align-center postTitle">
          <div class="d-flex align-center">
            <v-avatar class="mr-3 avatar-small">
              <v-img v-if="!post.profileimage || post.profileimage === ''" src="./images/default.webp"></v-img>
              <v-img v-else-if="post.profileimage" :src="post.profileimage"></v-img>
            </v-avatar>
            <div @click="$router.push(`/user/${post.username}`)" class="PostUserName">
              <h2 class="mb-0 small-text">{{ post.username }}</h2>
            </div>
            <v-chip class="ma-2 chip-small" color="grey darken-1" text-color="white" label>
              {{ formatDate(post.date) }}
            </v-chip>
          </div>
        </v-card-title>
        <div v-if="post.image">
          <v-img :src="post.image" class="postImg-small" cover height="160" @click="openImage(post.image)"></v-img>
        </div>

        <!-- Post content -->
        <v-card-text class="postText small-text">
          <p>{{ post.post }}</p>
        </v-card-text>
        <v-divider :thickness="2" class="border-opacity-20" color="white" width="100%"></v-divider>

        <v-card-actions class="d-flex align-center postActions">
          <v-icon @click="post.showComments = !post.showComments" color="grey darken-1" text-color="white" label>
            mdi-comment
          </v-icon>
          <v-chip class="ma-2 chip-small" color="grey darken-1" text-color="white" label>
            {{ post.comments.length }} Comments
          </v-chip>
        </v-card-actions>

        <v-divider :thickness="2" class="border-opacity-20" color="white" width="100%"></v-divider>

        <div class="d-flex flex-column align-start">
          <div v-if="post.showComments">
            <v-divider :thickness="2" class="border-opacity-20" color="white" width="100%"></v-divider>
            <div v-for="comment in post.comments" :key="comment.id" class="commentMainCon">
              <v-avatar class="mr-2 avatar-small">
                <v-img v-if="!comment.userImage" src="./images/default.webp"></v-img>
                <v-img v-else-if="comment.userImage" :src="comment.userImage"></v-img>
              </v-avatar>
              <div class="commentInsideCon" color="grey darken-1" text-color="white" label>
                <div class="mr-2 d-flex flex-column commentCont small-text">
                  <div class="d-flex">
                    <h3 class="mb-0">{{ comment.username }}</h3>
                  </div>
                  <span>{{ comment.comment }}</span>
                </div>


                <v-chip class="ma-2 chip-small dateCom" color="grey darken-1" text-color="white" label>
                  <v-icon>mdi-clock-time-four-outline</v-icon>
                  {{ formatDate(comment.date) }}
                </v-chip>
              </div>
            </div>
          </div>
        </div>
        <v-textarea label="Comment" v-model="comments[post._id]" rows="1" class="small-text"
          @keydown.enter="postComment(post._id)"></v-textarea>
      </v-card>
    </div>
  </div>
</template>

<script>
import authService from "../services/authService";
export default {
  data() {
    return {
      isReady: false,
      posts: [],
      userdata: {
        username: "",
        bio: "",
        age: "",
        location: "",
        socialLinks: {
          facebook: "",
          twitter: "",
          instagram: "",
        },
      },
      comments: {},
      postText: "",
    };
  },
  async mounted() {
    const userid = localStorage.getItem("user-id");
    try {
      const response = await authService.getUserData(userid);
      this.userdata = response.data;
      if (localStorage.getItem("userdata")) {
        localStorage.removeItem("userdata");
      }
      localStorage.setItem("userdata", JSON.stringify(response.data));
      this.profilePicture = response.data.profileimage;
      this.getFeed();
      this.isReady = true;
      this.$root.isReady = true;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async getFeed() {
      try {
        const response = await authService.getFeed();
        this.posts = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async postComment(postId) {
      try {
        const commentText = this.comments[postId]?.trim();
        if (commentText) {
          await authService.commentPost(
            postId,
            commentText,
            this.userdata._id,
            this.userdata.profileimage,
            this.userdata.username
          );
          this.comments[postId] = "";
          this.getFeed();
        }
      } catch (error) {
        console.error("Error posting comment:", error);
      }
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    async postContent() {
      if (this.postText) {
        try {
          await authService.postContent(this.postText, this.userdata._id);
          this.postText = "";
          this.getFeed();
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style scoped>
.feed {
  margin: 16px;
  height: 100%;
}

.PostUserName {
  cursor: pointer;
}

.v-text-field,
.v-textarea {
  background-color: #33333300 !important;
  color: white !important;
}

.post {
  margin: 10px;
  background-color: #1d1d1d8a;
  color: white;
  border-radius: 8px;
}

.avatar-small {
  width: 40px !important;
  height: 40px !important;
}

.small-text {
  font-size: 0.8rem;
}

.postTitle {
  font-size: 1rem;
  background-color: #333333;
  color: white;
  background-image: url(https://bouncy-vibes.co.uk/uploads/pages/ccf1f45-ws_Colorful_Equalizer_852x480.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  background-position: bottom;
}

.postText {
  margin: 10px;
  font-size: 0.9rem;
  text-align: start;

}

.postImg-small {
  height: 160px !important;
  border-radius: 8px;
}

.chip-small {
  font-size: 0.75rem;
  padding: 4px 8px;
}

.postActions {
  font-size: 0.8rem;
}

.commentMainCon {
  display: flex;
  margin-top: 12px;
}

.commentInsideCon {
  margin: 8px;
  background-color: #333333;
  padding: 8px;
  border-radius: 8px;
}

.commentCont {
  max-width: 400px;
  overflow-wrap: break-word;
  text-align: start;
}

.dateCom {
  font-size: 0.7rem;
  color: #bbbbbb;
}

.v-text-field,
.v-textarea {
  background-color: #333333;
  color: white;
}
</style>
