import { createRouter, createWebHistory } from 'vue-router';
import Register from './views/Register-user.vue';
import Login from './views/Login-user.vue';
import MainLayout from './components/NavLayout.vue'; // Import the new layout component
import schedule from './components/SchedulePanel.vue';
import UserProfile from './components/UserProfile.vue';
import MyProfile from './components/MyProfile.vue';
import Newsfeed from './components/NewsFeed.vue';
import WebChat from './components/Web-Chat.vue';
import AdminControl from './components/AdminControl.vue';
import ResetPassword from './views/ResetPassword.vue';
import UserTickets from './components/UserTickets.vue';
import Home from './views/Home.vue'; // Assuming Home.vue is in views directory
import axios from 'axios';


const routes = [{
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Login,
    },
    {
        path: '/Popchat',
        name: 'Popchat',
        component: WebChat,
        props: true,
    },
    {
        path: '/',
        component: MainLayout, // Use MainLayout for authenticated users
        children: [{
                path: '/home',
                name: 'Home',
                component: Home, // The default homepage for all visitors
            },
            {
                path: 'chat',
                name: 'Chat',
                component: WebChat,
            },
            {
                path: 'schedule',
                name: 'Schedule',
                component: schedule,
                props: true,
            },
            {
                path: 'tickets',
                name: 'Tickets',
                component: UserTickets,
            },
            {
                path: 'user/:djid',
                name: 'UserProfile',
                component: UserProfile,
                props: false,
            },
            {
                path: 'reset-password/:key',
                name: 'resetpassword',
                component: ResetPassword,
                props: false,
            },
            {
                path: 'myprofile',
                name: 'MyProfile',
                component: MyProfile,
                props: false,
            },
            {
                path: 'newsfeed',
                name: 'NewsFeed',
                component: Newsfeed,
                props: true,
            },
            {
                path: 'admin',
                name: 'Admin',
                component: AdminControl,
                props: true,
            }
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


// Add a navigation guard to check for authentication

router.beforeEach((to, from, next) => {

    //if domain is just / then redirect to /home
    if (to.path === '/') {
        return next('/home');
    }
    // Define routes that require authentication (case-insensitive)
    const authRequiredRoutes = ['/schedule', '/myprofile', '/newsfeed', '/chat', '/admin', '/user/:djid', '/tickets'];

    // Add dynamic routes as RegEx to match paths like '/user/bob'
    const dynamicAuthRoutes = [/^\/user\/\w+$/];

    // Normalize the path: remove trailing slash and convert to lowercase
    const normalizePath = (path) => path.replace(/\/$/, '').toLowerCase();
    const currentPath = normalizePath(to.path);

    // Check if the current route requires authentication
    const authRequired = authRequiredRoutes.map(route => normalizePath(route)).includes(currentPath) ||
        dynamicAuthRoutes.some((regex) => regex.test(currentPath));

    // Function to check authentication via API
    const checkAuthentication = () => {
        return axios.get('/api/auth/checkauth', { withCredentials: true }) // Include cookies with the request
            .then(response => response.data.authenticated) // Return true if authenticated
            .catch(() => false); // Return false if error or not authenticated
    };

    if (authRequired) {
        // If route requires authentication, check if the user is authenticated
        checkAuthentication().then(isAuthenticated => {
            if (!isAuthenticated) {
                // If not authenticated, save the intended route and redirect to login
                localStorage.setItem('redirectAfterLogin', to.fullPath);
                return next('/login');
            }
            // Proceed to the protected route if authenticated
            next();
        });
    } else {
        // If route does not require authentication, proceed as normal
        if (currentPath === '/login' || currentPath === '/register') {
            checkAuthentication().then(isAuthenticated => {
                if (isAuthenticated) {
                    return next('/'); // Redirect to home if already authenticated
                }
                next(); // Allow access to login/register if not authenticated
            });
        } else {
            next(); // Proceed as normal for public routes
        }
    }
});






export default router;