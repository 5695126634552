// src/services/authService.js
import axios from 'axios';

const API_URL = 'https://beta.bouncy-vibes.co.uk/api/auth';

const fetchCsrfToken = async() => {
    const response = await axios.get(`${API_URL}/authRequest`, { withCredentials: true });
    return response.data.csrfToken;
};

const register = async(username, password, email) => {
    const res = await axios.post(`${API_URL}/register`, { username, password, email });
    return res.data;
};


const login = async(username, password) => {
    const res = await axios.post(`${API_URL}/login`, { username, password });
    return res.data;
};

const logout = async() => {
        const res = await axios.get(`${API_URL}/logout`);
        return res.data;
    }
    //forgot password forgotPassword
const forgotPassword = async(email) => {
    const res = await axios.post(`${API_URL}/forgotPassword`, { email });
    return res.data;
};

const resetPassword = async(password, key) => {
    const res = await axios.post(`${API_URL}/resetPassword`, { password, key });
    return res.data;
}

const ChangeRole = async(userid, role) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/changeRole`, {
        userid,
        role
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const deleteUser = async(userid) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/deleteUser`, {
        userid
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const banUser = async(userid) => {
    const csrfToken = await fetchCsrfToken();
    const res = await axios.post(`${API_URL}/banUser`, {
        userid
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const getUserData = async(userid) => {
    const res = await axios.get(`${API_URL}/userdata?id=${userid}`);
    return res;
};

const getUserProfileData = async(djid) => {
    const res = await axios.get(`${API_URL}/getUserProfileData?id=${djid}`);
    return res;
};

const getSchedule = async() => {
    const res = await axios.get(`${API_URL}/schedule`);
    return res;

};

const bookSlot = async(day, djName, genre, timeSlot, timeStamp, profileimage, perm) => {
    console.log(day, djName, genre, timeSlot, timeStamp, profileimage, perm);
    const csrfToken = await fetchCsrfToken();
    const res = await axios.post(`${API_URL}/schedule/book`, {
        day,
        djName,
        genre,
        timeSlot,
        timeStamp,
        profileimage,
        perm
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken

        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const deleteSlot = async(day, djName, timeSlot) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/schedule/delete`, {
        day,
        djName,
        timeSlot,
    }, {
        headers: {
            'Content-Type': 'application/json',

            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const wipeDatabase = async() => {

    const csrfToken = await fetchCsrfToken();


    const res = await axios.post(`${API_URL}/wipe`, {
        key: 'Admin-Only',
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token


    });
    return res;
}

const postContent = async(post, userid) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/userPost`, {
        post,
        userid
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const getUserPosts = async(userid) => {
    const res = await axios.get(`${API_URL}/userPosts?id=${userid}`);
    return res;
};

const getUsers = async() => {
    const csrfToken = await fetchCsrfToken();
    const res = await axios.get(`${API_URL}/getUsers`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const getFeed = async() => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.get(`${API_URL}/getFeed`, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const deletePost = async(postid) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/deletePost`, {
        postid
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const updatePost = async(postid, post) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/updatePost`, {
        postid,
        post
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const commentPost = async(postid, comment, userid, userImage, username) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/commentPost`, {
        postid,
        comment,
        userid,
        userImage,
        username,
        date: new Date().toLocaleString()
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const uploadGif = async(gif, name) => {
    const csrfToken = await fetchCsrfToken();

    const formData = new FormData();
    formData.append('file', gif);
    formData.append('name', name);

    const res = await axios.post(`${API_URL}/uploadGif`, formData, {
        headers: {
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token
    });
    return res;
}


//getGifs
const getGifs = async() => {
    const res = await axios.get(`${API_URL}/getGifs`);
    return res;
}

//deleteGif
const deleteGif = async(gifid) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/deleteGif`, {
        gifid
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const uploadProfilePicture = async(image, userid) => {
    // Make an auth request first
    const csrfToken = await fetchCsrfToken();

    // Create a FormData object to hold the file and user ID
    const formData = new FormData();
    //Make sure to add the image to the form data as a file
    formData.append('file', image);
    console.log(image);
    formData.append('userid', userid);


    // Make the POST request to upload the profile picture
    const res = await axios.post(`${API_URL}/uploadProfilePicture`, formData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });

    return res;
}


const uploadCoverPhoto = async(image, userid) => {
    // Make an auth request first
    const csrfToken = await fetchCsrfToken();

    // Create a FormData object to hold the file and user ID
    const formData = new FormData();
    //Make sure to add the image to the form data as a file
    formData.append('file', image);
    console.log(image);
    formData.append('userid', userid);


    // Make the POST request to upload the profile picture
    const res = await axios.post(`${API_URL}/uploadCoverPhoto`, formData, {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });

    return res;
}


const followUser = async(followed, follower) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/followUser`, {
        followed,
        follower
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const unfollowUser = async(followed, unfollower) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/unfollowUser`, {
        unfollower,
        followed
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const getfollowers = async(userid) => {
    const res = await axios.get(`${API_URL}/getfollowers?id=${userid}`);
    return res;
};
const getfollowing = async(userid) => {
    const res = await axios.get(`${API_URL}/getfollowing?id=${userid}`);
    return res;
};

const updateProfile = async(userid, bio, age, location, sex, interests) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/updateProfile`, {
        userid,
        bio,
        age,
        location,
        sex,
        interests
    }, {

        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}

const createTicket = async(ticketData) => {
    const csrfToken = await fetchCsrfToken();
    const res = await axios.post(
        `${API_URL}/createTicket`, {
            ticketData
        }, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            withCredentials: true,
        }
    );
    return res;
};

const allTickets = async() => {
    const csrfToken = await fetchCsrfToken();
    const res = await axios.get(`${API_URL}/allTickets`, {
        headers: {
            'X-CSRF-Token': csrfToken,
        },
        withCredentials: true,
    });
    return res;
};

const addMessage = async(ticketId, messageData) => {
    const csrfToken = await fetchCsrfToken();

    try {
        // Send a POST request to the API endpoint for adding a message to a ticket
        const response = await axios.post(`${API_URL}/tickets/${ticketId}/messages`, messageData, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
        });
        return response;
    } catch (error) {
        console.error('Error adding message:', error);
        throw error; // Rethrow the error for further handling in the component
    }
};


const deleteTicket = async(ticketId) => {
    const csrfToken = await fetchCsrfToken();
    const res = await axios.post(`${API_URL}/deleteTicket`, {
        ticketId
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true,
    });
    return res;
};
const updateTicketStatus = async(ticketId, status) => {
    const csrfToken = await fetchCsrfToken();
    const res = await axios.post(`${API_URL}/updateTicketStatus`, {
        ticketId,
        status
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true,
    });
    return res;
};
const getUserTickets = async() => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.get(`${API_URL}/getUserTickets`, {
        headers: {
            'X-CSRF-Token': csrfToken,
        },
        withCredentials: true,
    });

    return res;
};

const fixprof = async(userid) => {
    const csrfToken = await fetchCsrfToken();

    const res = await axios.post(`${API_URL}/fixprof`, {
        userid
    }, {
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken
        },
        withCredentials: true, // This is important to send the CSRF token

    });
    return res;
}
export default {
    register,
    login,
    logout,
    getSchedule,
    bookSlot,
    deleteSlot,
    wipeDatabase,
    getUserData,
    postContent,
    getUserPosts,
    deletePost,
    updatePost,
    getUserProfileData,
    uploadProfilePicture,
    followUser,
    unfollowUser,
    getfollowers,
    getfollowing,
    updateProfile,
    uploadCoverPhoto,
    commentPost,
    getFeed,
    getUsers,
    forgotPassword,
    resetPassword,
    fetchCsrfToken,
    ChangeRole,
    deleteUser,
    banUser,
    uploadGif,
    getGifs,
    deleteGif,
    getUserTickets, // Fetch user tickets
    createTicket, // Create a ticket
    deleteTicket, // Delete a ticket
    updateTicketStatus, // Update ticket status
    addMessage, // Add a message to a ticket
    allTickets, // Fetch all tickets
    fixprof,
};