<template>
    <div class="resetView">
        <div class="resetContainer">
            <div class="resetContent">
                <h1 style="text-align: center;">Bouncy Vibes Radio</h1>
            </div>
            <div class="reset-form">
                <h2>Reset Password</h2>
                <v-form @submit.prevent="resetPassword">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="password" label="Password" type="password" required
                                    placeholder="Enter New Password"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-alert v-if="response" type="info">{{ response }}</v-alert>
                            </v-col>
                            <v-col justify="center">
                                <v-btn type="submit" color="primary">Reset</v-btn>
                                <v-divider></v-divider>
                                <v-chip color="blue" class="white--text" @click="this.$router.push('/login')">Login</v-chip>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

            </div>
        </div>
    </div>
</template>

<script>
import authService from '../services/authService';

export default {
    data() {
        return {
            password: '',
            response: '',
            key: ''
        };
    },
    methods: {
        resetPassword() {
            authService.resetPassword(this.password, this.key)
                .then(response => {
                    this.response = response.data.message;
                })
                .catch(error => {
                    this.response = error.response.data.message;
                });
        }

    },
    mounted() {
        this.key = this.$route.params.key;
        console.log(this.key); // Ensure that the key is being logged correctly
    }
};
</script>

<style scoped>
.resetView {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #424242;
    background-image: url(https://bouncy-vibes.co.uk/images/bg.jpg) !important;
    background-attachment: fixed !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;

}

.form-group {
    padding: 5px;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.reset-form {
    background-color: #191B1A;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 380px;
    margin: 0 auto;
    height: fit-content;
    border-radius: 40px 0px 40px 0px;
    color: white;
}

.resetContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.resetContent {
    background-color: #f2f2f2;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: 0 auto;
    background-image: url('../assets/art2.jpg');
    background-size: cover;

    background-position: center;

    height: 280px;
    width: 100%;
    color: white;
    text-shadow: 2px 2px 4px #000000;
    border-radius: 25px 0px 0px 25px;
}
</style>
