<template>
    <div class="loginView">
        <div class="LoginContainer">
            <div class="LoginContent">
                <h1 style="text-align: center;">Bouncy Vibes Radio</h1>
            </div>
            <div class="login-form">
                <h2>Please Login</h2>
                <v-form @submit.prevent="login">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field v-model="username" label="Username" required
                                    placeholder="Enter Username"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="password" label="Password" type="password" required
                                    placeholder="Enter Password"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-alert v-if="RegisterResponse" type="info">{{ RegisterResponse }}</v-alert>
                            </v-col>
                            <v-col justify="center">
                                <v-btn type="submit" color="primary">Login</v-btn>
                                <v-divider></v-divider>

                                <v-chip color="blue" class="white--text"
                                    @click="this.$router.push('/register')">Register</v-chip>
                                <v-chip color="blue" class="white--text" @click="dialog = true">Forgot Password</v-chip>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
        </div>
    </div>
    <!-- Dialog for forgot password -->
    <v-dialog v-model="dialog" max-width="290">
        <v-card>
            <v-card-title>
                <span class="headline">Forgot Password</span>
            </v-card-title>
            <v-card-text>
                <v-text-field v-model="email" label="Email" required placeholder="Enter Email"></v-text-field>
                <v-alert v-if="response" type="info">{{ response }}</v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn>
                <v-btn color="blue darken-1" text @click="forgotPassword">Send</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import authService from '../services/authService';

export default {
    data() {
        return {
            username: '',
            password: '',
            RegisterResponse: '',
            dialog: false,
            email: '',
            response: ''
        };
    },
    methods: {
        login() {
            authService.login(this.username, this.password).then(response => {
                this.response = response.message;
                if (response.userid) {
                    console.log("User ID: " + response.userid);
                    localStorage.setItem('user-id', JSON.stringify(response.userid));
                    // Check for a saved intended route
                    const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
                    localStorage.removeItem('redirectAfterLogin'); // Clear the saved route
                    this.$router.push(redirectTo); // Redirect to the intended route
                }
            }).catch(error => {
                this.RegisterResponse = error.response.data.msg; // Adjusted to use error.response
            });
        },
        forgotPassword() {
            authService.forgotPassword(this.email).then(response => {
                console.log(response);
                this.response = response.msg;
                this.dialog = false;
            }).catch(error => {
                this.RegisterResponse = error.response.data.msg; // Adjusted to use error.response
            });
        }
    },
    mounted() {
        //check if the route is /logout
        if (this.$route.path == '/logout') {
            console.log("Logging out");
            authService.logout();
        }

    }
};
</script>

<style scoped>
.loginView {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #424242;
    background-image: url(https://bouncy-vibes.co.uk/images/bg.jpg) !important;
    background-attachment: fixed !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100%;

}

.form-group {
    padding: 5px;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.login-form {
    background-color: #191B1A;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 380px;
    margin: 0 auto;
    height: fit-content;
    border-radius: 40px 0px 40px 0px;
    color: white;
}

.LoginContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.LoginContent {
    background-color: #f2f2f2;
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    width: 300px;
    margin: 0 auto;
    background-image: url('../assets/art2.jpg');
    background-size: cover;

    background-position: center;

    height: 280px;
    width: 100%;
    color: white;
    text-shadow: 2px 2px 4px #000000;
    border-radius: 25px 0px 0px 25px;
}
@media (max-width: 768px) {
    .LoginContainer {
        flex-direction: column;
        align-items: center;
    }

    .LoginContent {
        display: none; /* Hide the image div on smaller screens */
    }

    .login-form {
        width: 90%;
        max-width: 400px;
        border-radius: 15px;
    }

    h1 {
        font-size: 1.5rem;
    }

    h2 {
        font-size: 1.2rem;
    }
}

@media (max-width: 480px) {
    .login-form {
        padding: 10px;
    }

    h1 {
        font-size: 1.2rem;
    }

    h2 {
        font-size: 1rem;
    }
}
</style>
