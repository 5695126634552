<template>
    <div class="sched">
        <!-- Tabs for Days of the Week -->
        <v-tabs v-model="adjustedCurrentDay" background-color="deep-purple accent-4" dark class="sch-tabs">
            <v-tab v-for="(day, index) in days" :key="day.value" :disabled="index < todayDay - 1"
                @click="currentDay = (index === 6) ? 0 : index + 1">
                {{ day.text }}
            </v-tab>
            <v-spacer></v-spacer>
            <v-tab>

                <v-btn v-if="userdata.role === 'Admin'" color="red" @click="wipeDatabase">
                    <v-icon>mdi-delete</v-icon>
                    Wipe Database
                </v-btn>
            </v-tab>
        </v-tabs>

        <v-divider :thickness="3" color="white" class="m5"></v-divider>

        <!-- Schedule for Selected Day -->
        <v-row>
            <div class="sched-slots">
                <h2>{{ days[adjustedCurrentDay].text }} Schedule</h2>
                <p class="booking-policy">
                    You can only book slots in advance up to Sunday at midnight. The schedule will reset for Monday
                    bookings.
                </p>
                <div v-for="slot in filteredSchedule" :key="slot.timeSlot" class="time-slot">
                    <v-card class="mb-3 slot-card">
                        <v-card-title class="d-flex justify-space-between align-center slot-title">
                            <div class="d-flex align-center">
                                <v-avatar class="mr-3" size="25">
                                    <img :src="slot.djProfilePicture" alt="DJ Profile Picture" class="avatar-image" />
                                </v-avatar>
                                <div>
                                    <div class="d-flex flex-column">
                                        <div class="dj-info">

                                            <span v-if="slot.djName" class="dj-name">
                                                <span v-if="slot.perm" class="dj-genre" style="color:red;">
                                                    <v-icon>mdi-calendar-star</v-icon>
                                                    Permanent Slot
                                                </span>
                                                <v-icon>mdi-headphones</v-icon>

                                                Host :
                                                <router-link
                                                    :to="{ name: 'UserProfile', params: { djid: slot.djName } }"
                                                    class="UserLinks">
                                                    {{ slot.djName }}
                                                </router-link>
                                                - <v-icon>mdi-speaker</v-icon>
                                                Playing : <v-icon>mdi-music</v-icon>
                                                {{ slot.genre }}

                                            </span>

                                            <span v-else class="dj-name">
                                                <v-icon>mdi-bullhorn</v-icon>
                                                Available Slot :
                                                <v-btn class="justify-center" color="blue"
                                                    @click="bookSlot(slot.timeSlot)">
                                                    <v-icon>mdi-calendar-plus</v-icon>
                                                    Book Now
                                                </v-btn>

                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="time-slot-text">
                                <v-btn v-if="slot.djName && (slot.djName === userdata.username || admin)" color="red"
                                    @click="deleteSlot(slot.timeSlot)">
                                    <v-icon>mdi-delete</v-icon>
                                    Cancel
                                </v-btn>
                                {{ slot.timeSlot }}
                            </div>
                        </v-card-title>
                    </v-card>
                </div>
            </div>
        </v-row>
    </div>

    <!-- Booking Dialog -->
    <v-dialog v-model="bookingDialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">Book DJ Slot</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-text-field v-model="form.username" label="Username" readonly></v-text-field>
                    <v-text-field v-model="form.genre" label="Genre"></v-text-field>
                    <v-checkbox v-model="form.permanent" label="Permanent Slot"></v-checkbox>
                    <p>Booking slot for {{ selectedTimeSlot }} ({{ form.timeStamp }}) on {{ selectedDay.text }}</p>
                    <v-avatar class="mt-3" size="72">
                        <img :src="userdata.profileimage" alt="DJ Profile Picture" class="avatar-image" />
                    </v-avatar>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="bookingDialog = false">Close</v-btn>
                <v-btn color="blue darken-1" text @click="confirmBooking">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import authService from '../services/authService';

export default {
    name: 'Schedule-Panel',
    data() {
        return {
            admin: false,
            userdata: {},
            schedule: [], // Initial schedule data array
            currentDay: new Date().getDay(), // Current day as index for tabs
            todayDay: new Date().getDay(), // To disable past days
            days: [{
                text: 'Monday',
                value: 0
            },
            {
                text: 'Tuesday',
                value: 1
            },
            {
                text: 'Wednesday',
                value: 2
            },
            {
                text: 'Thursday',
                value: 3
            },
            {
                text: 'Friday',
                value: 4
            },
            {
                text: 'Saturday',
                value: 5
            },
            {
                text: 'Sunday',
                value: 6
            }
            ],
            bookingDialog: false,
            selectedTimeSlot: '',
            selectedDay: {},
            form: {
                username: '',
                genre: '',
                timeStamp: ''
            }
        };
    },
    computed: {
        adjustedCurrentDay() {
            // Mapping getDay() value to match the days array index
            let currentDayIndex = this.currentDay === 0 ? 6 : this.currentDay - 1;

            // Return the adjusted index
            return currentDayIndex;
        },
        filteredSchedule() {
            if (!this.schedule || this.schedule.length === 0) return [];

            const daySchedule = this.schedule.find(
                day => day.day === this.days[this.adjustedCurrentDay].text
            );

            return daySchedule ? daySchedule.slots : [];
        }
    },
    mounted() {

    },
    async beforeMount() {
        try {
            const response = await authService.getUserData();
            this.userdata = response.data;
            console.log('Loaded user data:', this.userdata);
            this.loggedIn = true;
            if (this.userdata.role === 'Admin') {
                this.admin = true;
            }
            localStorage.setItem('userdata', JSON.stringify(response.data));
            this.PreloadSchedule();
        } catch (error) {
            console.error('Error loading user data:', error);
        }
    },
    methods: {
        async PreloadSchedule() {
            try {
                const response = await authService.getSchedule();
                console.log('Loaded schedule:', response.data);

                this.schedule = response.data;
            } catch (error) {
                console.error('Error loading schedule:', error);
            }
        },
        bookSlot(timeSlot) {
            const selectedSlot = this.filteredSchedule.find(slot => slot.timeSlot === timeSlot);
            if (selectedSlot) {

                this.selectedTimeSlot = timeSlot;

                // Use adjustedCurrentDay instead of this.currentDay
                this.selectedDay = this.days[this.adjustedCurrentDay];
                this.form.username = this.userdata.username;
                this.form.genre = '';
                this.form.timeStamp = selectedSlot.timeStamp;
                this.bookingDialog = true;
            } else {
                console.error('Error booking slot:');
            }
        },
        async confirmBooking() {
            try {
                await authService.bookSlot(
                    this.selectedDay.text,
                    this.form.username,
                    this.form.genre,
                    this.selectedTimeSlot,
                    this.form.timeStamp,
                    this.userdata.profileimage,
                    this.form.permanent
                );
                this.bookingDialog = false;
                this.PreloadSchedule();
            } catch (error) {
                console.error('Error booking slot:', error);
            }
        },
        async deleteSlot(timeSlot) {
            console.log('Deleting slot:', timeSlot + ' on ' + this.selectedDay.text);
            this.selectedDay = this.days[this.adjustedCurrentDay];
            try {
                await authService.deleteSlot(
                    this.selectedDay.text,
                    this.userdata.username,
                    timeSlot);
                this.PreloadSchedule();
            } catch (error) {
                console.error('Error deleting slot:', error);
            }
        },
        async wipeDatabase() {
            console.log('Wiping database');
            try {
                let response = await authService.wipeDatabase();

                // Check if response is 200
                console.log(response);
                if (response.status === 200) {
                    // Reload schedule
                    this.schedule = [];
                    this.PreloadSchedule();
                } else {
                    console.log('Error wiping database:', response);
                }
            } catch (error) {
                console.error('Error wiping database:', error);
            }
        }

    }

};
</script>

<style scoped>
.slot-title {
    height: 40px !important;
}

.sched {
    margin: 20px;
}

.UserLinks {
    color: white;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;

}

.UserLinks:hover {
    color: grey;
}

.time-slot {
    display: flex;
    flex-direction: column;
    margin-bottom: 1px;
}

.v-card-title {
    font-size: 16px;
}

.slot-card {
    padding: 10px;
    color: white;
    background-color: #1f1f1f;
    border-radius: 14px;
    background-image: url(https://bouncy-vibes.co.uk/uploads/pages/ccf1f45-ws_Colorful_Equalizer_852x480.jpg);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-position: bottom;
}

.sched-slots {
    margin-top: 20px;
    width: 100%;
    padding: 20px;
}

.dj-info {
    display: flex;
    flex-direction: column;
}

.dj-name {
    font-size: 16px;
    margin-right: 10px;
}

.dj-genre {
    font-size: 16px;
}

.time-slot-text {
    font-size: 16px;
}

.v-avatar {
    height: 25px;
    width: 25px;
}

.avatar-image {
    width: 100%;
}

.v-btn {
    font-size: 16px;
}
</style>
