<template>

    <div>This is Beta Release - Please report any bugs to the developer</div>
    <v-card class="mx-auto chathead" dark>


        <v-card-text class="bg-surface-dark pt-4">
            <div class="d-flex" id="chatContainer">

                <!-- Remote video streams -->
                <div class="d-flex CamsChat">
                    <div class="camHead">
                        <h3>Live Cams</h3>
                        <div id="remoteCams" class="video-grid"></div>
                    </div>

                    <div class="chat-box">
                        <div class="chat-head">
                            <h3>Live Chat</h3>
                        </div>

                        <div class="chat-messages"></div>

                        <div class="chat-input-box d-flex">
                            <div class="chat-input-container d-flex flex-column">

                                <div class="chat-input-info d-flex">
                                    <v-icon @click="giftab = true">mdi-gif</v-icon> <!-- Gif icon -->
                                    <v-img @click="getbvgifs" src="https://bouncy-vibes.co.uk/bvplayer/play.gif" alt="Camera Icon" width="15" height="15"></v-img> <!-- Camera icon -->
                                    <v-icon>mdi-pallete</v-icon> <!-- Color icon -->
                                </div>
                                <div class="chat-input d-flex">
                                    <v-text-field v-model="postText" label="Post something..." outlined clearable
                                        @keyup.enter="SendMsg()"
                                        :rules="[v => !!v || 'Message is required', v => v.length <= 300 || 'Message must be less than 300 characters']">
                                    </v-text-field>
                                    <v-btn class="sendBtn" @click="SendMsg"><v-icon>mdi-send</v-icon> Send</v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <v-btn icon @click="sideToggle = !sideToggle" class="side-toggle">
                    <div v-if="sideToggle">
                        <v-icon>mdi-chevron-right</v-icon>
                    </div>
                    <div v-else>
                        <v-icon>mdi-chevron-left</v-icon>
                    </div>
                </v-btn>
                <div class="side-panel" v-if="sideToggle">
                    <div class="MyCamPanel d-flex flex-column">
                        <div class="MyCam-Head">
                            <h3>My Cam</h3>
                        </div>
                        <div class="d-flex flex-column align-center camCon">
                            <h3>{{ userdata.username }}</h3>
                            <img v-if="!isOnCam" :src="userdata.profileimage" alt="Profile Image"
                                style="width: 150px; height: 150px; border-radius: 10%; margin: 0 auto;">

                            <video v-else ref="MyCam" autoplay playsinline muted
                                style="width: 150px; height: 150px; border-radius: 10%; margin: 0 auto;">
                            </video>

                            <div class="CamBtn" v-if="!isOnCam" @click="startCamera">
                                <v-icon>mdi-camera</v-icon>
                            </div>
                            <div class="CamBtn" v-else @click="stopCamera">
                                <v-icon>mdi-camera-off</v-icon>
                            </div>
                        </div>
                    </div>

                    <div class="user-list">
                        <div class="user-head">
                            <h3>Users</h3>
                        </div>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>

    <!--make a model for the gif search-->
    <v-dialog v-model="giftab" max-width="800">
        <v-card>
            <v-card-title>Search for a GIF</v-card-title>
            <v-card-text>
                <v-text-field v-model="gifSearch" label="Search for a GIF" outlined clearable
                    @keyup.enter="searchGifs()"></v-text-field>
                <v-btn color="primary" @click="searchGifs">Search</v-btn>
                <div class="d-flex flex-wrap">
                    <v-img v-for="gif in gifs" :key="gif.id" :src="gif.images.original.url" @click="addGif(gif)"
                        class="gif"></v-img>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
     <!-- BV Gifs Dialog -->
     <v-dialog v-model="bvgiftab" max-width="800">
        <v-card>
            <v-card-title>
                <span>Bv Gifs</span>
            </v-card-title>
            <v-card-text>
                <!-- Search Input for Gifs -->
                <v-text-field
                    v-model="bvSearchGifs"
                    label="Search for a GIF"
                    outlined
                    clearable
                    @input="filterBvGifs"
                ></v-text-field>
                <div class="d-flex flex-wrap">
                    <!-- Filtered GIFs -->
                    <v-img
                        v-for="gif in filteredBvGifs"
                        :key="gif._id"
                        :src="gif.gif"
                        @click="bvaddGift(gif.gif)"
                        class="gif"
                    ></v-img>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<style>

/* Added styles */
:root {
    --bvblue: #0089E3;
    --bvdark: #424242;
    --bvlight: #d7d7d7;
}
@media screen and (max-width: 800px) {
        .side-panel {
            width: 50% !important;
        }
        .CamsChat {
            width: 100% !important;
        }
    
}
.side-toggle {
    position: absolute;
    top: 20%;
    right: 0;
    transform: translateY(-50%);
    color: white;
    border-radius: 1;
    padding: 10px;
    cursor: pointer;
}
.SendBtn {
    background-color: var(--bvblue) !important;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}
.messageText {
    color: #fff;
    word-break: break-word;
    overflow-wrap: break-word;
    margin-left: 5px;
}

.remote-video-container {
    margin: 5px;
}

.profile-container {
    position: relative;
    padding: 0;
}

.gif {
    width: 100px;
    height: 100px;
    margin: 5px;
    cursor: pointer;
}

.gif:hover {
    border: 2px solid var(--bvblue);
    cursor: pointer;
}

.chat-input-container {
    width: 100%;
}

/* Re-added your original styles */
.d-flex.flex-column.align-center.camCon {
    min-height: 200px;
    max-height: 200px;
}

.chat-input-box {
    flex: 0 0 auto;
}

.side-panel {
    width: 15%;
}

.user-head {
    background-color: var(--bvblue) !important;
    color: white !important;
}

.MyCam-Head {
    background-color: var(--bvblue) !important;
    color: white !important;
}

div.CamBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bvblue);
    color: white;
    width: 50px;
    height: 50px;
    border-radius: 20%;
    margin: 0 auto;
    margin-top: 10px;
    cursor: pointer;
}

.camHead {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 0px;
    background-color: var(--bvblue);
}

.chat-head {
    flex: 0 0 auto;
    margin: 0px;
    padding: 0px;
    background-color: var(--bvblue);
    margin-bottom: 20px;
}

.chathead {
    background-color: #191B1A !important;
    color: white !important;
}

.remote-webcam {
    width: 130px;
    height: auto;
    border-radius: 5px;
}

.MyCamPanel {
    color: white;
    background-color: #212121;
    margin: 10px;
}

.user-list {
    color: white;
    min-width: 15%;
    background-color: #212121;
    margin: 10px;
    min-height: 450px;
    max-height: 450px;
    margin-top: 20px;
    overflow: auto;
}

.user-list-item {
    display: flex;
    margin: 10px;
    padding: 5px;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}

.user-list-item:hover {
    background-color: var(--bvblue);
    cursor: pointer;
}

div#remoteCams {
    min-height: 140px;
    background-color: #212121;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
}

.chat-box {
    min-height: 480px;
    background-color: #212121;
    margin: 10px;
    min-height: 510px;
    max-height: 510px;
    display: flex;
    flex-direction: column;
}

div#chatContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.CamsChat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-right: 5px;
}

.chat-messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Ensure messages align to the left */
    padding: 10px;
    overflow-y: auto;
    /* Allow scrolling */
    background-color: #212121;
    flex: 1 1 auto;
}

.chat-message {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 5px;
}

.username-header {
    background: var(--bvblue);
    color: white;
    margin-top: 10px;
}
.ServerMsg {
    width: 100%;
    position: relative;
}
</style>

<script>
import io from "socket.io-client";
import authService from '../services/authService';


export default {

    data() {
        return {
            sideToggle: true,   
            messageFormats: {
                bold: false,
                underline: false,
                italic: false,
            },
            gifs: [],
            giftab: false,
            bvgiftab: false,
            bvGifs: [],
            bvSearchGifs: '',
            filteredBvGifs: [],
            socket: null,
            messageHistory: [],
            postText: '',
            localStream: null,
            isOnCam: false,
            peers: {},
            userslist: [],

            userdata: {
                username: '',
                bio: '',
                age: '',
                location: '',
                socialLinks: {
                    facebook: '',
                    twitter: '',
                    instagram: ''
                }
            }
        };
    },
    beforeUnmount() {
         if (this.socket) {
             this.socket.disconnect();
             if (this.localStream) {
                 this.localStream.getTracks().forEach(track => track.stop());
             }
             console.log('Socket disconnected!');
         }
    },
    async mounted() {
        const userid = localStorage.getItem('user-id');

        try {
            const response = await authService.getUserData(userid);
            this.userdata = response.data;
            localStorage.setItem('userdata', JSON.stringify(response.data));
        } catch (error) {
            console.error("Error fetching user data:", error);
        }

        this.socket = io('wss://beta.bouncy-vibes.co.uk:3000', {
            query: { userdata: JSON.stringify(this.userdata) }
        });

        this.socket.on('chat-history', (history) => {
            this.messageHistory = history;
            console.log('Chat history:', this.messageHistory);
            this.messageHistory.forEach((message) => {
                if (message.length < 1) return;
                this.addMessageToChat(message.message, message.username, message._id, message.profileimage, message.type);
            });
        });

        this.socket.on('user-connected', ({ socketID, onlineUsers }) => {
            Object.keys(onlineUsers).forEach((key) => {
                this.addusertolist(onlineUsers[key]);
                onlineUsers[key].socketid = key;
            });
            this.createPeerConnection(socketID, true);
            
            setTimeout(() => {
                this.serverMessage('user-connected', socketID);
            }, 2000);

        });

        this.socket.on('chat-message', ({ message, sender, id, profileimg, type }) => {
            this.addMessageToChat(message, sender, id, profileimg, type);
        });

        this.socket.on('cam-connected', (socketID) => {
            this.createPeerConnection(socketID, false);
        });

        this.socket.on('cam-disconnected', (socketID) => {
            if (this.peers[socketID]) {
                this.peers[socketID].close();
                delete this.peers[socketID];
            }
            const remoteVideo = document.getElementById(socketID);
            if (remoteVideo) {
                remoteVideo.remove();
            }
        });

        this.socket.on('user-disconnected', ({ socketID, _id }) => {
            if (this.peers[socketID]) {
                this.peers[socketID].close();
                delete this.peers[socketID];
            }
            const remoteVideo = document.getElementById(socketID);
            if (remoteVideo) {
                remoteVideo.remove();
            }
            const userIndex = this.userslist.findIndex(user => user._id === _id);
            if (userIndex > -1) {
                this.userslist.splice(userIndex, 1);
            }
            this.serverMessage('user-disconnected', socketID);
        });

        this.socket.on('offer', async ({ sdp, sender }) => {
            const peerConnection = this.createPeerConnection(sender, false);
            await peerConnection.setRemoteDescription(new RTCSessionDescription(sdp));
            const answer = await peerConnection.createAnswer();
            await peerConnection.setLocalDescription(answer);
            this.socket.emit('answer', { target: sender, sdp: peerConnection.localDescription });
        });

        this.socket.on('answer', async ({ sdp, sender }) => {
            const peerConnection = this.peers[sender];
            await peerConnection.setRemoteDescription(new RTCSessionDescription(sdp));
        });

        this.socket.on('ice-candidate', async ({ candidate, sender }) => {
            const peerConnection = this.peers[sender];
            if (peerConnection) {
                await peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
            }
        });

        //create a set interval to check if the user peers track is available 

    },
    methods: {
        async getbvgifs() {
            try {
                const response = await authService.getGifs();
                this.bvGifs = response.data; // Assign raw GIF data
                this.filteredBvGifs = response.data; // Initialize filtered GIFs
                this.bvgiftab = true; // Open the dialog
            } catch (error) {
                console.error('Failed to fetch GIFs:', error);
            }
        },
        // Filter BV GIFs based on search query
        filterBvGifs() {
            const searchQuery = this.bvSearchGifs.toLowerCase();
            this.filteredBvGifs = this.bvGifs.filter((gif) =>
                gif.name.toLowerCase().includes(searchQuery)
            );
        },
        serverMessage(type, socketID) {
            //get the uname of the user from the userlist
            const user = this.userslist.find(user => user.socketid === socketID);
            let message = '';
            if (type === 'user-connected') {
                message = `
                <div class="ServerMsg" style="color: #fff; background-color: #424242;">
                    <div class="ServerMsgText">${user.username} has connected</div>
                </div>`;
            }

            if (type === 'user-disconnected') {
                message = `
                <div class="ServerMsg" style="color: #fff; background-color: #424242;">
                    <div class="ServerMsgText">${user.username} has disconnected</div>
                </div>`;
            }

            //add the message to the chat
            document.querySelector('.chat-messages').innerHTML += message;
            document.querySelector('.chat-messages').scrollTop = document.querySelector('.chat-messages').scrollHeight;

        },

        searchGifs() {
            fetch(`https://api.giphy.com/v1/stickers/search?q=${this.gifSearch}&api_key=7QcshDMAzYGjznmEI5NwQoYVxGj3fYFe&limit=20`)
                .then(response => response.json())
                .then(data => {
                    this.gifs = data.data;
                })
                .catch(error => console.error('Error fetching gifs:', error));
        },
        addGif(gif) {
            this.socket.emit('chat-message', { message: gif.images.original.url, sender: this.userdata.username, id: Math.floor(Math.random() * 1000000), type: 'gif' });
            this.gifs = [];
            this.gifSearch = '';
            this.giftab = false;
        },
        bvaddGift(gif) {
            this.socket.emit('chat-message', { message: gif, sender: this.userdata.username, id: Math.floor(Math.random() * 1000000), type: 'bvgif' });
            this.bvgiftab = false;
        },
        SendMsg() {
            if (!this.postText) return;
            const id = Math.floor(Math.random() * 1000000);
            this.socket.emit('chat-message', { message: this.postText, sender: this.userdata.username, id, type: 'message' });
            this.postText = '';
        },

        addMessageToChat(message, username, id, profileimg, type) {
            console.log('Adding message to chat:', message, username, id, profileimg, type);
            if (!profileimg) {
                const user = this.userslist.find(user => user.username === username);
                profileimg = user ? user.profileimage : './images/default.webp';
            }
            let chatMessage = '';
            if (type === 'gif') {
                chatMessage = `
                <div class="chat-message" data-tag="chat-message[${id}]">
                    <img src="${profileimg}" alt="${username}" style="width: 30px; height: 30px; border-radius: 50%; margin-right: 5px;">
                    <div class="messageName">${username}: </div>
                    <img src="${message}"  style="width: 100px; height: 100px;">
                </div>`;
            } else if (type === 'bvgif') {
                chatMessage = `
                <div class="chat-message" data-tag="chat-message[${id}]">
                    <img src="${profileimg}" alt="${username}" style="width: 30px; height: 30px; border-radius: 50%; margin-right: 5px;">
                    <div class="messageName">${username}: </div>
                    <img src="${message}"  style="width: 100px; height: 100px;">
                </div>`;
            }else if (type === 'image') {
                chatMessage = `
                <div class="chat-message" data-tag="chat-message[${id}]">
                    <img src="${profileimg}" alt="${username}" style="width: 30px; height: 30px; border-radius: 50%; margin-right: 5px;">
                    <div class="messageName">${username}: </div>
                    <img src="${message}" alt="User Image" style="width: 100px; height: 100px;">
                </div>`;
            }
            else if (type === 'message') {
                chatMessage = `
                <div class="chat-message" data-tag="chat-message[${id}]">
                    <img src="${profileimg}" alt="${username}" style="width: 30px; height: 30px; border-radius: 50%; margin-right: 5px;">
                    <div class="messageName">${username}: </div>
                    <div class="messageText">${message}</div>
                </div>`;
            }

            if (!document.querySelector(`[data-tag="chat-message[${id}]`)) {
                document.querySelector('.chat-messages').innerHTML += chatMessage;
                document.querySelector('.chat-messages').scrollTop = document.querySelector('.chat-messages').scrollHeight;
            }
        },

        addusertolist(userdata) {
            if (!userdata.username) return;
            this.userslist.push(userdata);
            if (!document.querySelector(`[data-tag="userlist[${userdata._id}]`)) {
                const UserListElement = `
                    <div class="user-list-item" data-tag="userlist[${userdata._id}]">
                        <img src="${userdata.profileimage}" alt="${userdata.username}" style="width: 30px; height: 30px; border-radius: 50%; margin-right: 5px;">
                        <span>${userdata.username}</span>
                    </div>`;
                document.querySelector('.user-list').innerHTML += UserListElement;
            }
        },

        async startCamera() {
            this.isOnCam = true;
            try {
                this.localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
                this.$refs['MyCam'].srcObject = this.localStream;

                // Apply constraints
                this.localStream.getVideoTracks()[0].applyConstraints({
                    width: { ideal: 320 },
                    height: { ideal: 240 },
                    frameRate: { ideal: 15 }
                });

                // Notify the server
                this.socket.emit('cam-connected', this.socket.id);

                // Add tracks to peer connections
                Object.keys(this.peers).forEach((socketId) => {
                    this.addTracksToPeer(this.peers[socketId]);
                    this.createOffer(this.peers[socketId], socketId);
                });

                // Add the local camera to UI
                this.addLocalCameraToUI();
            } catch (error) {
                console.error("Error accessing webcam:", error);
            }
        }
        ,

        stopCamera() {
            if (this.localStream) {
                Object.keys(this.peers).forEach((socketId) => {
                    const senders = this.peers[socketId].getSenders();
                    const sender = senders.find(s => s.track && s.track.kind === 'video');
                    if (sender) {
                        this.peers[socketId].removeTrack(sender);
                    }
                });
                this.localStream.getTracks().forEach(track => track.stop());
            }
            this.isOnCam = false;
            this.socket.emit('cam-disconnected', this.socket.id);

            // Clear the local UI for this camera
            const localCamElement = document.getElementById(this.socket.id);
            if (localCamElement) {
                localCamElement.remove();
            }
        }
        ,

        createPeerConnection(socketId, initiator) {
            const peerConnection = new RTCPeerConnection({ iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] });

            peerConnection.ontrack = (event) => {
                console.log('Received remote stream:', event.streams[0]);

                let remoteVideo = document.getElementById(socketId);
                const username = this.userslist.find(user => user.socketid === socketId)?.username || 'Remote User';

                if (remoteVideo) {
                    // Remove the existing video element before adding the new one
                    remoteVideo.remove();
                }

                const remoteVideoContainer = document.createElement('div');
                remoteVideoContainer.className = 'remote-video-container';
                remoteVideoContainer.id = socketId;

                const usernameHeader = document.createElement('div');
                usernameHeader.className = 'username-header';
                usernameHeader.innerText = username;

                remoteVideo = document.createElement('video');
                remoteVideo.className = 'remote-webcam';
                remoteVideo.srcObject = event.streams[0];
                remoteVideo.muted = true;
                remoteVideo.autoplay = true;
                remoteVideo.playsinline = true;

                remoteVideoContainer.appendChild(usernameHeader);
                remoteVideoContainer.appendChild(remoteVideo);
                document.getElementById('remoteCams').appendChild(remoteVideoContainer);

                this.userOnCam(socketId);
            };

            peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    this.socket.emit('ice-candidate', { target: socketId, candidate: event.candidate });
                }
            };

            this.peers[socketId] = peerConnection;

            if (initiator && this.localStream) {
                this.addTracksToPeer(peerConnection);
                this.createOffer(peerConnection, socketId);
            }

            return peerConnection;
        },
        userOnCam(socketId) {
            console.log('User on cam:', socketId);

        },


        addTracksToPeer(peerConnection) {
            if (this.localStream) {
                this.localStream.getTracks().forEach(track => peerConnection.addTrack(track, this.localStream));
            }
        },

        createOffer(peerConnection, socketId) {
            peerConnection.createOffer()
                .then(offer => peerConnection.setLocalDescription(offer))
                .then(() => this.socket.emit('offer', { target: socketId, sdp: peerConnection.localDescription }))
                .catch(error => console.error('Error creating offer:', error));
        },

        addLocalCameraToUI() {
            const myCamContainer = document.createElement('div');
            myCamContainer.className = 'remote-video-container';
            myCamContainer.id = this.socket.id;

            const usernameHeader = document.createElement('div');
            usernameHeader.className = 'username-header';
            usernameHeader.innerText = this.userdata.username;

            const myCam = document.createElement('video');
            myCam.className = 'remote-webcam';
            myCam.srcObject = this.localStream;
            myCam.autoplay = true;
            myCam.playsinline = true;
            myCam.muted = true;

            myCamContainer.appendChild(usernameHeader);
            myCamContainer.appendChild(myCam);
            document.getElementById('remoteCams').appendChild(myCamContainer);
        }
    }
};
</script>