import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

// Vuetify
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetify = createVuetify({
    components,
    directives,
    theme: {
        defaultTheme: 'dark', // Set the default theme to dark globally
        themes: {
            dark: {
                dark: true, // Enable dark mode
                colors: {
                    primary: '#1E88E5', // Customize primary color
                    secondary: '#424242', // Customize secondary color
                    background: '#121212', // Dark background color
                    surface: '#222222', // Dark surface color
                    error: '#FF5252',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FB8C00',
                },
            },
        },
    },
});

createApp(App).use(router).use(vuetify).mount('#app');