<template>
    <v-card>
        <v-tabs v-model="tab" bg-color="dark">
            <v-tab :value="0">Dashboard</v-tab>
            <v-tab :value="1">Users</v-tab>
            <v-tab :value="2">Posts</v-tab>
            <v-tab :value="3">Chat</v-tab>
            <v-tab :value="4">Radio Station</v-tab>
            <v-tab :value="5">Tickets</v-tab>
        </v-tabs>

        <v-card-text class="admin-control">
            <v-tab-items v-model="tab">
                <v-tab-item v-if="tab === 0">
                    <div>
                        <h1>Dashboard</h1>
                        <p>Welcome Admin, {{ userdata.username }}</p>

                        <!-- Overview Cards Section -->
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-card class="pa-4" outlined>
                                    <v-card-title>Active Users</v-card-title>
                                    <v-card-subtitle>{{ activeUsers }} Users</v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card class="pa-4" outlined>
                                    <v-card-title>Current Listeners</v-card-title>
                                    <v-card-subtitle>{{ player.listeners }} Listening Now</v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card class="pa-4" outlined>
                                    <v-card-title>Chat Messages Today</v-card-title>
                                    <v-card-subtitle>243 Messages</v-card-subtitle>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card class="pa-4" outlined>
                                    <v-card-title>Total Posts</v-card-title>
                                    <v-card-subtitle>{{ posts.length }} Posts</v-card-subtitle>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-divider></v-divider>
                                <v-card class="pa-4" outlined>
                                    <v-card-title>Website Traffic</v-card-title>
                                    <v-card-subtitle>Visitors and Page Views</v-card-subtitle>
                                    <v-data-table :items="trafficLogs" :headers="headers" class="elevation-1">
                                        <template #[`item.timestamp`]="{ item }">
                                            <span>{{ new Date(item.timestamp).toLocaleString() }}</span>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-tab-item>

                <v-tab-item v-if="tab === 1">
                    <div class="admin-control">
                        <h1>Users</h1>
                        <v-text-field v-model="searchQuery" label="Search Users" prepend-icon="mdi-magnify"
                            clearable></v-text-field>
                            <v-btn color="primary" @click="fixprof">Fix Profile Image</v-btn>
                        <div class="user-grid">
                            <div class="user-card" v-for="user in filteredUsers" :key="user.id">
                                <img :src="user.profileimage || './assets/default-profile.png'" alt="Profile"
                                    class="profile-picture" />
                                <div class="user-info">
                                    <div v-if="user.role === 'VIP'" class="d-flex flex-column">
                                        <div class="d-flex flex-row"><v-icon>mdi-star</v-icon> VIP</div>
                                        {{ user.username }}
                                    </div>
                                    <div v-if="user.role === 'Admin'" class="d-flex flex-column">
                                        <div class="d-flex flex-row"><v-icon>mdi-shield-account</v-icon> ADMIN</div>
                                        {{ user.username }}
                                    </div>
                                    <div v-if="user.role === 'Moderator'" class="d-flex flex-column">
                                        <div class="d-flex flex-row"><v-icon>mdi-account-star</v-icon> MODERATOR</div>
                                        {{ user.username }}
                                    </div>
                                    <div v-if="user.role === 'dj'" class="d-flex flex-column">
                                        <div class="d-flex flex-row"><v-icon>mdi-music</v-icon> DJ</div>
                                        {{ user.username }}
                                    </div>
                                    <div v-if="user.role === 'banned'" class="d-flex flex-column">
                                        <div class="d-flex flex-row"><v-icon>mdi-account-off</v-icon> BANNED</div>
                                        {{ user.username }}
                                    </div>
                                    <div v-if="user.role === 'user'" class="d-flex flex-column">
                                        <div class="d-flex flex-row"><v-icon>mdi-account</v-icon> USER</div>
                                        {{ user.username }}
                                    </div>
                                    <v-btn color="primary" @click="openUserDialog(user)">
                                        View
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <v-dialog v-model="userDialog" max-width="500px">
                            <v-card>
                                <v-card-title>
                                    <span class="headline">{{ selectedUser?.username }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Email:</v-list-item-title>
                                                <v-list-item-subtitle>{{ selectedUser?.email }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Role:</v-list-item-title>
                                                <v-select v-model="selectedRole" :items="roleOptions"
                                                    label="Select Role"></v-select>
                                                <v-btn v-if="selectedRole !== selectedUser?.role" color="success"
                                                    @click="saveUserRole" class="mt-3">
                                                    Save
                                                </v-btn>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Account Created:</v-list-item-title>
                                                <v-list-item-subtitle>{{ selectedUser?.createdAt
                                                    }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>Status:</v-list-item-title>
                                                <v-list-item-subtitle>{{ selectedUser?.status || 'Active'
                                                    }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                                <v-card-actions class="justify-center flex-wrap">
                                    <v-btn color="primary" @click="viewUserProfile(selectedUser)">
                                        View Profile
                                    </v-btn>
                                    <v-btn color="error" @click="deleteUserAccount(selectedUser)">
                                        Delete Account
                                    </v-btn>
                                    <v-btn color="warning" @click="banUserAccount(selectedUser)">
                                        Ban Account
                                    </v-btn>
                                    <v-btn color="secondary" @click="requestPasswordReset(selectedUser)">
                                        Reset Password
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeUserDialog">
                                        Close
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </v-tab-item>

                <v-tab-item v-if="tab === 2">
                    <div class="admin-control">
                        <h1>Posts</h1>
                        <p>Posts will be displayed here</p>
                        <v-data-table :items="posts" :headers="PostHeaders" class="elevation-1">
                            <template #[`item.actions`]="{ item }">
                                <v-btn color="error" @click="deletePost(item)">
                                    Delete
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                </v-tab-item>

                <v-tab-item v-if="tab === 3">
                    <div class="admin-control">
                        <h1>Chat</h1>
                        <p>Chat Settings will be displayed here</p>

                        <!-- Submenu Tabs -->
                        <v-tabs v-model="chatSubTab" background-color="grey lighten-4" slider-color="primary"
                            class="mb-4" dense>
                            <v-tab :value="0">GIF Management</v-tab>
                        </v-tabs>
                        <v-tab-items v-model="chatSubTab">
                            <v-tab-item v-if="chatSubTab === 0">
                                <!-- GIF Upload Section -->
                                <v-card class="pa-4 mb-6" outlined>
                                    <h2>Upload GIF</h2>
                                    <v-form ref="gifForm" v-model="gifFormValid">
                                        <v-file-input v-model="newGif" label="Select GIF" accept="image/gif"
                                            prepend-icon="mdi-upload" required :rules="[v => !!v || 'GIF is required']"
                                            dense></v-file-input>
                                        <!--name for gif-->
                                        <v-text-field v-model="gifName" label="GIF Name" required dense></v-text-field>
                                        <v-alert v-if="!gifFormValid" type="error">Please select a GIF</v-alert>
                                        <v-alert v-if="gifres">{{ gifres }}</v-alert>
                                        <v-btn color="primary" :disabled="!gifFormValid || uploadingGif"
                                            @click="uploadGif" class="mt-4">
                                            <v-progress-circular v-if="uploadingGif" indeterminate color="white"
                                                size="20" class="mr-2"></v-progress-circular>
                                            Upload GIF
                                        </v-btn>
                                    </v-form>
                                </v-card>

                                <!-- GIFs Table Section -->
                                <v-card class="pa-4 mb-6" outlined>
                                    <h2>Uploaded GIFs</h2>
                                    <v-data-table :items="gifs" :headers="gifHeaders" class="elevation-1">
                                        <template #[`item.gif`]="{ item }">
                                            <v-img :src="item.gif" width="100" height="100"></v-img>
                                        </template>
                                        <template #[`item.actions`]="{ item }">
                                            <v-btn color="error" @click="deleteGif(item)">
                                                Delete
                                            </v-btn>
                                        </template>
                                    </v-data-table>
                                </v-card>
                            </v-tab-item>
                            <v-tab-item v-if="chatSubTab === 1">
                                <h2>Chat Commands</h2>
                                <p>Chat Commands will be displayed here</p>
                            </v-tab-item>
                        </v-tab-items>
                    </div>
                </v-tab-item>

                <v-tab-item v-if="tab === 4">
                    <div class="admin-control">
                        <h1>Radio Station Settings</h1>
                        <p>Below are the details and current status of the radio station.</p>

                        <v-row dense>
                            <!-- Station Info -->
                            <v-col cols="12" md="6">
                                <v-card outlined>
                                    <v-card-title class="primary--text">📡 Station Info</v-card-title>
                                    <v-card-text>
                                        <v-list dense>
                                            <v-list-item>
                                                <v-list-item-title><strong>Name:</strong> {{ player.raw?.station?.name
                                                    }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title><strong>URL:</strong>
                                                    <a :href="player.raw?.station?.url" target="_blank">
                                                        {{ player.raw?.station?.url }}
                                                    </a>
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title><strong>Frontend:</strong> {{
                                                    player.raw?.station?.frontend
                                                }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title><strong>Backend:</strong> {{
                                                    player.raw?.station?.backend
                                                }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title><strong>Timezone:</strong> {{
                                                    player.raw?.station?.timezone
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Current Listeners -->
                            <v-col cols="12" md="6">
                                <v-card outlined>
                                    <v-card-title class="success--text">👥 Current Listeners</v-card-title>
                                    <v-card-text>
                                        <v-list dense>
                                            <v-list-item>
                                                <v-list-item-title><strong>Total:</strong> {{
                                                    player.raw?.listeners?.total
                                                }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title><strong>Unique:</strong> {{
                                                    player.raw?.listeners?.unique
                                                }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title><strong>Current:</strong> {{
                                                    player.raw?.listeners?.current
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row dense class="mt-4">
                            <!-- Now Playing -->
                            <v-col cols="12" md="6">
                                <v-card outlined>
                                    <v-card-title class="info--text">🎵 Now Playing</v-card-title>
                                    <v-card-text>
                                        <v-list dense>
                                            <v-list-item>
                                                <v-list-item-title><strong>Title:</strong> {{
                                                    player.raw?.now_playing?.song?.title
                                                }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title><strong>Elapsed:</strong> {{
                                                    formatTime(player.raw?.now_playing?.elapsed) }}</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title><strong>Remaining:</strong> {{
                                                    formatTime(player.raw?.now_playing?.remaining)
                                                }}</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        <v-img :src="player.raw?.now_playing?.song?.art" max-height="150"
                                            class="mt-2"></v-img>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <!-- Live Status -->
                            <v-col cols="12" md="6">
                                <v-card outlined>
                                    <v-card-title class="warning--text">🎙️ Live Status</v-card-title>
                                    <v-card-text>
                                        <v-list dense>
                                            <v-list-item>
                                                <v-list-item-title>
                                                    <strong>Is Live:</strong> {{ player.raw?.live?.is_live ? 'Yes' :
                                                        'No' }}
                                                </v-list-item-title>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-title v-if="player.raw?.live?.streamer_name">
                                                    <strong>Streamer:</strong> {{ player.raw?.live?.streamer_name }}
                                                </v-list-item-title>
                                                <v-list-item-title v-else>
                                                    <strong>Streamer:</strong> No live streamer
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Mount Points -->
                        <v-row dense class="mt-4">
                            <v-col cols="12">
                                <v-card outlined>
                                    <v-card-title class="secondary--text">🖧 Mount Points</v-card-title>
                                    <v-card-text>
                                        <v-list dense>
                                            <v-list-item v-for="(mount, index) in player.raw?.station?.mounts"
                                                :key="index">
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        <strong>{{ mount.name }}</strong>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        <p><strong>Bitrate:</strong> {{ mount.bitrate }} kbps</p>
                                                        <p><strong>Format:</strong> {{ mount.format }}</p>
                                                        <p><strong>Listeners:</strong> {{ mount.listeners?.current }}
                                                        </p>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                </v-tab-item>

                <v-tab-item v-if="tab === 5">
                    <div class="admin-control">
                        <h1>Tickets</h1>
                        <p>Manage user tickets here</p>
                        <v-data-table :items="tickets" :headers="ticketHeaders" class="elevation-1">
                            <template #[`item.title`]="{ item }">
                                <span>{{ item.title }}</span>
                            </template>
                            <template #[`item.priority`]="{ item }">
                                <span>{{ item.priority }}</span>
                            </template>
                            <template #[`item.status`]="{ item }">
                                <span>{{ item.status }}</span>
                            </template>
                            <template #[`item.actions`]="{ item }">
                                <v-btn color="primary" :disabled="item.status === 'Closed'"
                                    @click="openTicketDialog(item)">
                                    View
                                </v-btn>
                                <v-btn color="red" :disabled="item.status === 'Closed'" @click="closeTicket(item._id)">
                                    Close
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>

                </v-tab-item>


            </v-tab-items>
        </v-card-text>
    </v-card>
</template>

<script>
import authService from '../services/authService';

export default {
    props: {
        player: {
            type: Object,
            required: true,
        },
    },
    data() {

        return {
            tab: 0,
            userdata: {},
            userlist: [],
            searchQuery: '',
            userDialog: false,
            selectedUser: null,
            selectedRole: null,
            roleOptions: ['Admin', 'VIP', 'Moderator', 'user', 'dj', 'banned'],
            trafficLogs: [],
            headers: [
                { title: 'IP Address', key: 'ip', align: 'start' },
                { title: 'Path', key: 'path', align: 'start' },
                { title: 'Method', key: 'method', align: 'center' },
                { title: 'Date & Time', key: 'timestamp', align: 'end' }
            ],
            ticketHeaders: [
                { title: "Title", key: "title", align: "start" },
                { title: "Priority", key: "priority", align: "start" },
                { title: "Status", key: "status", align: "start" },
                { title: "Actions", key: "actions", align: "end", sortable: false },
            ],
            tickets: [],
            activeUsers: 0,
            posts: [],
            PostHeaders: [
                { title: 'id', key: '_id', align: 'start' },
                { title: 'username', key: 'username', align: 'center' },
                { title: 'post', key: 'post', align: 'center' },
                { title: 'Date & Time', key: 'date', align: 'end' },
                { title: 'Actions', key: 'actions', align: 'end' }
            ],
            chatSubTab: 0,
            newGif: null,
            gifFormValid: false,
            uploadingGif: false,
            gifs: [],
            gifName: '',
            gifres: '',
            gifHeaders: [
                { title: 'ID', key: '_id' },
                { title: 'Name', key: 'name' },
                { title: 'GIF', key: 'gif' },
                { title: 'Actions', key: 'actions' }
            ]

        };
    },
    computed: {
        filteredUsers() {
            if (!this.searchQuery) {
                return this.userlist;
            }
            return this.userlist.filter(user =>
                user.username.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        }
    },
    methods: {
        formatTime(seconds) {
            const h = Math.floor(seconds / 3600);
            const m = Math.floor((seconds % 3600) / 60);
            const s = Math.floor(seconds % 60);
            return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
        },
       async fixprof () {
            try {
                const response = await authService.fixprof();
                console.log(response);
                //reload users
                this.getUsers();
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        },

        async allTickets() {
            try {
                const response = await authService.allTickets();
                this.tickets = response.data;
            } catch (error) {
                console.error('Error fetching tickets:', error);
            }
        },
        async deleteGif(gif) {
            try {
                await authService.deleteGif(gif._id);
                this.getGifs();
            } catch (error) {
                console.error('Error deleting GIF:', error);
            }
        },
        async uploadGif() {
            this.uploadingGif = true;
            try {
                const response = await authService.uploadGif(this.newGif, this.gifName);
                console.log(response);
                if (response.status === 200) {
                    this.$refs.gifForm.reset();
                    this.getGifs();
                    this.newGif = null;
                    this.gifName = '';
                    this.gifFormValid = false;
                    this.gifres = response.data.message;
                    console.log(response.data.message);

                }

            } catch (error) {
                console.error('Error uploading GIF:', error);
                this.gifres = error.response.data.message;
            } finally {
                this.uploadingGif = false;
            }
        },
        async getGifs() {
            try {
                const response = await authService.getGifs();
                this.gifs = response.data;
            } catch (error) {
                console.error('Error fetching GIFs:', error);
            }
        },
        async getUsers() {
            try {
                const response = await authService.getUsers();
                this.userlist = response.data;
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        },

        async getFeed() {
            try {
                const response = await authService.getFeed();
                this.posts = response.data;
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        },
        openUserDialog(user) {
            this.selectedUser = user;
            this.selectedRole = user.role;
            this.userDialog = true;
        },
        closeUserDialog() {
            this.userDialog = false;
            this.selectedUser = null;
            this.selectedRole = null;
        },
        viewUserProfile(user) {
            this.$router.push(`/user/${user.username}`);
        },
        async deleteUserAccount(user) {
            try {
                await authService.deleteUser(user._id);
                this.closeUserDialog();
                this.getUsers();
            } catch (error) {
                console.error('Error deleting user:', error);
            }
        },
        async banUserAccount(user) {
            try {
                await authService.banUser(user._id);
                this.closeUserDialog();
                this.getUsers();
            } catch (error) {
                console.error('Error banning user:', error);
            }
        },
        async requestPasswordReset(user) {
            try {
                await authService.forgotPassword(user.email);
                this.closeUserDialog();
                this.getUsers();
            } catch (error) {
                console.error('Error requesting password reset:', error);
            }
        },
        async saveUserRole() {
            try {
                await authService.ChangeRole(this.selectedUser._id, this.selectedRole);
                this.selectedUser.role = this.selectedRole;
                this.closeUserDialog();
                this.getUsers();
            } catch (error) {
                console.error('Error updating user role:', error);
            }
        }
    },
    watch: {
        tab() {
            if (this.tab === 1) {
                this.getUsers();
            }
            if (this.tab === 2) {
                this.getFeed();
            }
            if (this.tab === 3) {
                this.getGifs();
            }
            if (this.tab === 5) {
                this.allTickets();
            }
        }
    },
    async mounted() {
        console.log(this.player.raw);
        const userid = localStorage.getItem('user-id');
        try {
            const response = await authService.getUserData(userid);
            this.userdata = response.data;
            // If the user role is not admin, redirect to home
            if (this.userdata.role !== 'Admin') {
                this.$router.push('/');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }

        try {
            const response = await fetch('/api/auth/getTraffic');
            this.trafficLogs = await response.json();
        } catch (error) {
            console.error('Failed to fetch traffic logs:', error);
        }
        try {
            const FetchactiveUsers = await fetch('/api/auth/activeUsersCount');
            const activeUsers = await FetchactiveUsers.json();
            this.activeUsers = activeUsers.activeUsers;
        } catch (error) {
            console.error('Failed to fetch active users:', error);
        }

        try {
            const response = await authService.getFeed();
            this.posts = response.data;
        } catch (error) {
            console.error('Error fetching posts:', error);
        }
    }
};
</script>

<style scoped>
.admin-control {
    padding: 20px;
    background-color: #333131;
}

.user-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
}

.user-card {
    width: 150px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #222222;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>