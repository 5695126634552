<template>
    <v-container class="home-page" fluid>
        <!-- Top Header with Nav -->
        <div class="top-header d-flex" justify="space-between">
            <div class="logo" style="display: flex; align-items: center; margin: 20px;" justify="start">
                <img src="https://bouncy-vibes.co.uk/images/logo.gif" alt="Bouncy Vibes Radio Logo"
                    style="width: 180px;">
            </div>
        </div>

        <v-row class="navBar" style="color: white;">
            <v-col cols="8">
                <v-tabs background-color="transparent" color="white" dark>
                    <v-tab @click="changeTab('Home')">Home</v-tab>
                    <v-tab @click="changeTab('About')">About</v-tab>
                    <v-tab @click="NavigateTo('Chat')">Chatroom</v-tab>
                    <v-tab to="/Schedule">Schedule</v-tab>
                    <v-tab to="/Store">Store</v-tab>
                    <v-tab to="/Contact">Contact</v-tab>
                </v-tabs>
            </v-col>


        </v-row>

        <!-- Main Content Area -->
        <v-row class="main-content">
            <v-col cols="8" class="dj-section">
                <div class="dj-card" v-if="tab === 'Home'">
                    <div class="dj-head d-flex flex-column justify-center align-center">
                        <h3 style="text-align: center;">Welcome to Bouncy Vibes Radio</h3>
                        <span style="text-align: center;">Playing the best in UK Hardcore, Happy Hardcore, Drum & Bass,
                            and more!</span>
                    </div>
                    <div class="dj-body">
                        <iframe data-v-fae5bece="" allowfullscreen="" frameborder="0" title="Live Stream"
                            src="https://stream.bouncy-vibes.co.uk/" style="height: 400px;width: 100%;"></iframe>
                        <div class="dj-text">
                            Bouncy Vibes Radio has been around for more than 20 years, many people may know of or have
                            heard of BVR and some may not. This is the place to be, we are an online radio community, we
                            bring a lot to the internet.
                            Our DJ’s will be supplying you with the best in Dance, Hardcore, Scouse/Bounce, Hard House,
                            Hard Trance, Techno, Electro, Drum & Bass, Trance, House, Hardcore & Gabber we do it all! we
                            have a webcam chatroom on the site which people love.
                        </div>
                    </div>
                </div>

                <div class="dj-card" v-if="tab === 'About'">
                    <div class="dj-head d-flex flex-column justify-center align-center">
                        <h3 style="text-align: center;">About</h3>
                        <span style="text-align: center;">Terms & Conditions</span>
                    </div>
                    <div class="dj-body">
                        <div class="dj-text">
                            <span>
                                <p>
                                    <strong>Bouncy Vibes Radio (BVR)</strong> has been around for more than ten years. Many people may know of or have heard of BVR, and some may not. BVR is the place to be; we are an online radio community bringing a lot to the internet.
                                </p>
                                <p>
                                    BVR is a welcoming site; anyone is welcome, DJ or not—it does not matter. Our aim is simply to give people the music that they love. Our DJs will be supplying you with the best in Dance, Hardcore, Scouse/Bounce, Hard House, Hard Trance, Techno, Electro, Drum & Bass, Trance, House, Hardcore & Gabber—we do it all! We have a webcam chatroom on the site which people love.
                                </p>
                                <p>
                                    The banter is always brilliant, fantastic people who are always up for a good laugh and chill out to the tunes. So why not come join us in the chat room, tune in, and set yourself up for a night of nothing but banging anthems and nonstop music all night long!! Know anyone who is a lover of music? Then pass them the link and get them also involved with the amazing tunes. See you soon folks, and remember... Keep on raving!!
                                </p>
                                <p>
                                    We look forward to welcoming new DJs to BVR. If you’re interested in hosting your own show with us, please drop us a message via the contact form on the website or a direct inbox on Facebook.
                                </p>
                            
                                <h3>THIS IS NOT A PLACE FOR:</h3>
                                <ul>
                                    <li>❌ Sexually Explicit Posts</li>
                                    <li>❌ Racist Jokes or Comments</li>
                                    <li>❌ Sacrilegious Joking</li>
                                    <li>❌ Negativity</li>
                                    <li>❌ Rude or Mean Comments</li>
                                    <li>❌ Drug-Related Posts</li>
                                </ul>
                            
                                <h3>GUIDELINES:</h3>
                                <ul>
                                    <li>No links to gore, violence, blood, or copyrighted material of any kind in chat.</li>
                                    <li>Spamming in chat will result in a warning and possibly getting muted.</li>
                                    <li>Please do not advertise during a live set of a DJ, unless it is the live DJ's own material linked (e.g., Facebook live, SoundCloud, MixCloud etc.).</li>
                                    <li>Banter is okay so long as it does not escalate too far.</li>
                                    <li>Please refrain from heavy discussions as it usually becomes drama or a heated battle between two sides or people.</li>
                                    <li>Please do not abuse the Whisper/PM features, do not PM live DJs during their show/set.</li>
                                    <li>When in doubt, Mods and Admins hold final and complete judgment.</li>
                                    <li>No discussion of illegal content, Torrenting, Piracy, etc., keep sharing away from public chat.</li>
                                    <li>Do not be an unwanted person, this is a music-related community. The keyword is COMMUNITY.</li>
                                    <li>Preferably keep any discussion in English.</li>
                                    <li>Pushing the Rules will be counted as breaking the rules.</li>
                                    <li>If you have an invisible name, offensive name, or a name that is too hard to mention or see, you will be asked to change it.</li>
                                    <li>If you ban/mute evade or use it for any evading, you will be banned based on other identifying markers.</li>
                                    <li>Only staff may share radio details; when in doubt, pass on the request to any STAFF member.</li>
                                    <li>As a DJ, refrain from music with lyrics that may unintentionally be considered as offensive to some.</li>
                                    <li>Music with explicit themes and/or lyrics is allowed if not offensive to our audience. And only in moderation.</li>
                                    <li>We assume the music you play in this music community is from legit origin!</li>
                                    <li>When sharing song titles, all rules apply to the tags shown in current playing songs, be sure to have them tagged accordingly.</li>
                                    <li>DJs and staff are the business card of the community, make sure you as a DJ or staff act accordingly.</li>
                                    <li>At all times, the DJs are responsible for the material they play, BV merely offers a platform and resources for broadcasting.</li>
                                    <li>Chat Cam Screen Captures & GIFs of Members are not welcomed on-site unless you have their permission to use the image.</li>
                                    <li>MP3 Music Sharing in chat is not welcomed on this website.</li>
                                    <li>No Gif Bullying of any kind in chat.</li>
                                    <li>Failure to adhere to the Terms and Conditions set out above may result in a permanent ban from all our services, and where appropriate, you may be reported to the relevant authorities which could lead to criminal charges.</li>
                                </ul>
                                <p>Follow these few simple guidelines and we'll all have a good time!</p>
                            </span>
                            
                            
                        </div>
                    </div>
                </div>
                <div class="dj-card" v-if="tab === 'Chatroom'">
                    <div class="dj-head d-flex flex-column justify-center align-center">
                        <h3 style="text-align: center;">Chatroom</h3>
                        <span style="text-align: center;">Join our fun & friendly chatroom for live video feed direct
                            from the studio including live DJ interaction. Login with your Facebook or Twitter
                            account!</span>
                    </div>
                    <div class="dj-body">
                        <div class="dj-text">
                            <span>
                                Join our fun & friendly chatroom for live video feed direct from the studio including
                                live
                                DJ interaction. Login with your Facebook or Twitter account!
                            </span>
                        </div>
                    </div>
                </div>
            </v-col>

            <!-- Right Sidebar with Alexa, Live Chat -->
            <v-col cols="4" class="side-section">
                <v-card class="alexa-section">
                    <v-card-title>Alexa Ready</v-card-title>
                    <img src="https://bouncy-vibes.co.uk/img/alexa.png" alt="Alexa Logo" style="width: 160px;">
                    <v-card-text>
                        <p>Say "Alexa, Play Bouncy Vibes Radio" or "Alexa, Launch Bouncy Vibes Radio" to tune in!</p>
                        <v-btn color="orange" dark href="https://www.amazon.co.uk/dp/B0B7S2WWNK?"> Tune In Now </v-btn>
                    </v-card-text>
                </v-card>

                <v-card class="live-chat-section" style="margin-top: 20px;">
                    <v-card-title>Live Chatroom</v-card-title>
                    <img src="https://www.bouncy-vibes.co.uk/uploads/livechat.png" alt="Live Chat Logo"
                        style="width: 120px;">
                    <v-card-text>
                        Join our fun & friendly chatroom for live video feed direct from the studio including live DJ
                        interaction. Login with your Facebook or Twitter account!
                        <v-btn color="blue" dark> Chat Now! </v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- Footer with Social Links -->
        <v-row class="footer">
            <v-col>
                <v-btn icon href="https://facebook.com">
                    <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn icon href="https://twitter.com">
                    <v-icon>mdi-twitter</v-icon>
                </v-btn>
                <v-btn icon href="https://instagram.com">
                    <v-icon>mdi-instagram</v-icon>
                </v-btn>
                <v-btn icon href="https://reddit.com">
                    <v-icon>mdi-reddit</v-icon>
                </v-btn>
                <v-btn icon href="https://youtube.com">
                    <v-icon>mdi-youtube</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import authService from '../services/authService';
export default {
    name: 'HomePage',
    data() {
        return {
            tab: "Home",
            userdata: {},
            loggedIn: false,
        };
    },
    mounted() {
        const userid = localStorage.getItem('user-id');
        authService.getUserData(userid).then((response) => {
            this.userdata = response.data;
            this.loggedIn = true;
            localStorage.setItem('userdata', JSON.stringify(response.data));

        }).catch((error) => {
            console.log(error);
        });
    },
    methods: {
        changeTab(tab) {
            this.tab = tab;
        },
        NavigateTo(route) {
            console.log("Navigating to: " + route);
            this.$router.push("/" + route);

        },
    }
};
</script>

<style scoped>


.body {

    height: 100%;
}

.app {
    height: 100%;
}

.home-page {
    background-image: url(https://bouncy-vibes.co.uk/images/bg.jpg) !important;
    background-attachment: fixed !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    height: 100%;
    padding-bottom: 80px;
    /* Adjust to avoid overlap with the fixed footer */
    min-height: 100vh;
    /* Ensures full-height for the container */
}

.top-header {
    background-color: #0069cb00;
    color: white;
    padding: 2px;
    justify-content: space-between;
    margin-left: 250px;
    margin-right: 250px;
}

.dj-card {
    border: 1px solid #0068CB;
    color: white;

}

.dj-head {
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url(https://bouncy-vibes.co.uk/uploads/pages/ccf1f45-ws_Colorful_Equalizer_852x480.jpg);
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    background-position: center;
    /* This centers the background */
    margin-bottom: 5px;
    color: white;
}

.dj-text {
    padding: 20px;
    color: white;

        text-align: left;
        margin: 20px;
}

.status-info {
    color: white;
}

.main-content {
    margin-left: 250px;
    margin-right: 250px;
}

.navBar {
    margin-left: 250px;
    margin-right: 250px;
}

.alexa-section {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: 1px solid #0068CB;
    padding: 10px;
    margin-bottom: 20px;
}

.live-chat-section {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: 1px solid #0068CB;
    padding: 10px;
    margin-bottom: 20px;
}
</style>
