<template>
<v-container class="profile-container">
    <!-- Cover Photo -->
    <v-img :src="userdata.coverphoto" class="cover-photo" cover height="400">
        <v-icon class="coverUpIcon" @click="uploadCoverPhoto" style="font-size:50px; position: absolute;">mdi-camera</v-icon>
    </v-img>
    <!--upload cover photo button-->
    <input type="file" ref="coverInput" @change="handleCoverUpload" style="display: none">

    <!-- Profile Info -->
    <v-row class="profile-info">
        <v-col cols="12" md="2">
            <!-- Profile Picture -->
            <v-avatar size="200">
                <v-img v-if="!profilePicture && !uploadingImage" src="./images/default.webp"></v-img>
                <v-img v-else-if="profilePicture" :src="profilePicture"></v-img>
                <v-progress-circular class="profileUpIcon" v-if="uploadingImage" indeterminate color="blue"></v-progress-circular>
                <v-icon v-else class="profileUpIcon" @click="uploadProfilePicture" style="cursor: pointer; font-size:50px">mdi-camera</v-icon>
            </v-avatar>
            <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none">

        </v-col>
        <!-- Profile Details -->
        <div class="profile-details">
            <h1 class="profile-name">{{ userdata.username }}</h1>
            <p class="profile-friends">{{followers.length}} Followers</p>
        </div>
    </v-row>
    <!-- add some menu tabs here like about, followers, following -->
    <v-tabs>
        <v-tab @click="switchTab(0)">About</v-tab>
        <v-tab @click="switchTab(1)">Followers {{ followers.length }}</v-tab>
        <v-tab @click="switchTab(2)">Following {{ following.length }}</v-tab>
        <v-tab @click="switchTab(3)">Tracks / Mixes</v-tab>
    </v-tabs>
    <div class="d-flex ProfileContainer">
        <div class="TabContent">
            <v-card v-if="activeTab === 0" class="mx-auto Tabs" dark>
                <v-card-text>
                    <div class="d-flex align-center mb-4 ProfileDetails">
                        <div class="d-flex flex-column mb-4">
                            <h2>About Me</h2>
                            <p v-if="!userdata.bio">You haven't added a bio yet. edit your profile to add one.</p>
                            <p v-else>{{ userdata.bio }}</p>
                        </div>
                        <v-divider :thickness="2" class="border-opacity-20" color="white" width="100%"></v-divider>
                        <div class="d-flex align-center mb-4 flex-column">
                            <h3>Age: {{ userdata.age }}</h3>
                            <h3>Location: {{ userdata.location }}</h3>
                            <h3>Gender: {{ userdata.sex }}</h3>
                            <h3>Role: {{ userdata.role }}</h3>
                            <h4>Date Joined: {{ formatDate(userdata.date) }}</h4>
                        </div>
                        <v-divider :thickness="2" class="border-opacity-20" color="white" width="100%"></v-divider>
                        <div class="d-flex align-center mb-4 flex-column">
                            <h3>Interests</h3>
                            <p>{{ userdata.interests}}</p>
                        </div>
                        <v-divider :thickness="2" class="border-opacity-20" color="white" width="100%"></v-divider>

                        <h3>Social Links</h3>
                        <v-row>
                            <v-col cols="12">
                                <v-btn icon href="{{ socialLinks.facebook }}" target="_blank" class="mr-2">
                                    <v-icon>mdi-facebook</v-icon>
                                </v-btn>
                                <v-btn icon href="{{ socialLinks.twitter }}" target="_blank" class="mr-2">
                                    <v-icon>mdi-twitter</v-icon>
                                </v-btn>
                                <v-btn icon href="{{ socialLinks.instagram }}" target="_blank" class="mr-2">
                                    <v-icon>mdi-instagram</v-icon>
                                </v-btn>

                            </v-col>
                        </v-row>

                        <v-btn @click="editProfileDialog = true">
                            Edit Profile
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </div>
                </v-card-text>
                <v-dialog v-model="editProfileDialog" max-width="600px">
                    <v-card dark>
                        <v-card-title>
                            <span class="headline">Edit Profile</span>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form">
                                <v-textarea v-model="userdata.bio" label="Bio"></v-textarea>
                                <v-text-field v-model="userdata.age" label="Age" type="number"></v-text-field>
                                <v-text-field v-model="userdata.location" label="Location"></v-text-field>
                                <v-select v-model="userdata.sex" :items="['Male', 'Female', 'Private']" label="Gender"></v-select>
                                <v-text-field v-model="userdata.interests" label="Interests"></v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="editProfileDialog = false">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="updateProfile">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card>
            <v-card v-else-if="activeTab === 1" class="mx-auto Tabs" dark>
                <v-card-text>
                    <h2>Followers {{ followers.length }}</h2>
                    <div class="d-flex flex-wrap space-evenly">
                        <div v-for="follower in followers" :key="follower.id" @click="viewProfile(follower.username)" class="follower-container">
                            <v-avatar size="50" style="margin: 10px">
                                <v-img :src="follower.profileimage"></v-img>
                            </v-avatar>
                            <v-chip>{{ follower.username }}</v-chip>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <v-card v-else-if="activeTab === 2" class="mx-auto Tabs" dark>
                <v-card-text>
                    <h2>Following {{ following.length }}</h2>
                    <div class="d-flex flex-wrap space-evenly">
                        <div v-for="follow in following" :key="follow.id" @click="viewProfile(follow.username)" class="follower-container">
                            <v-avatar size="50" style="margin: 10px">
                                <v-img :src="follow.profileimage"></v-img>
                            </v-avatar>
                            <v-chip>{{ follow.username }}</v-chip>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
            <v-card v-else-if="activeTab === 3" class="mx-auto Tabs" dark>
                <v-card-text>
                    <h2 class="mb-4">Tracks / Mixes</h2>
                    <v-btn @click="uploadtrack = true" style="margin-bottom: 10px">Upload New Track
                        <v-icon>mdi-music-note</v-icon>
                    </v-btn>

                    <v-dialog v-model="uploadtrack" max-width="600px">
                        <v-card dark>
                            <v-card-title>
                                <span class="headline">Upload New Track</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="form">
                                    <v-text-field v-model="newTrack.title" label="Track Name" required></v-text-field>
                                    <v-file-input v-model="newTrack.file" label="Drag & Drop MP3 File / Or Click To Select" accept=".mp3" prepend-icon="mdi-music-note" required></v-file-input>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="uploadTrack">Upload</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <div class="d-flex flex-column space-evenly">
                        <div v-for="track in tracks" :key="track.id" class="track-container">
                            <div class="track-player d-flex">
                                <img src="/images/art1.jpg" alt="track image" class="track-image">
                                <div class="d-flex flex-column player-container">
                                    <div class="d-flex align-center top-section">
                                        <div icon color="grey darken-4" class="mr-3 play-button" @click="playTrack(track.id)">
                                            <div v-if="isPlaying(track.id)" style="font-size: 24px">
                                                ⏸️
                                            </div>
                                            <div v-else style="font-size: 24px">
                                                ▶️
                                            </div>
                                        </div>
                                        <h2 class="track-title">{{userdata.username}} - {{ track.title }}</h2>
                                    </div>
                                    <div class="waveform-container">
                                        <div :ref="`waveform-${track.id}`" class="waveform"></div>
                                    </div>
                                </div>
                            </div>

                            <div class="track-actions display-flex justify-end align-center">
                                <div class="track-Likes">
                                    <v-chip class="ma-2" color="grey darken-1" text-color="white" label>
                                        10 Likes
                                    </v-chip>
                                </div>
                                <v-chip class="ma-2" color="grey darken-1" text-color="white" label>
                                    {{ formatDate(track.date) }}
                                </v-chip>
                                <v-btn class="trackActions" icon dark small @click="editTrack(track)">
                                    <v-icon color="white">mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn class="trackActions" icon dark small @click="deleteTrack(track.id)">
                                    <v-icon color="white">mdi-delete</v-icon>
                                </v-btn>

                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <div class="postArea">
            <v-card class="mx-auto postCard" subtitle="Tell us something about yourself!" dark>
                <template v-slot:title>
                    <span class="font-weight-black">Posts</span>
                </template>

                <v-card-text class="bg-surface-dark">
                    <v-textarea label="Whats On Your Mind" variant="outlined" v-model="postText" rows="1"></v-textarea>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="green" @click="postContent">Post</v-btn>
                </v-card-actions>
            </v-card>

            <!--add a card -->
            <v-card v-for="post in UserPosts" :key="post.id" class="postCard" dark>

                <!-- Post header with avatar and username -->
                <v-card-title class="d-flex justify-space-between align-center postTitle">
                    <div class="d-flex align-center">
                        <v-avatar class="mr-3" size="50">
                            <v-imm v-if="!profilePicture" src="./images/default.webp"></v-imm>
                            <v-img v-else-if="profilePicture" :src="profilePicture"></v-img>
                        </v-avatar>
                        <h2 class="mb-0">{{ userdata.username }}</h2>
                        <v-chip class="ma-2" color="grey darken-1" text-color="white" label>
                            {{ formatDate(post.date) }}
                        </v-chip>
                    </div>
                </v-card-title>
                <div v-if="post.image">
                    <v-img :src="post.image" class="postImg" cover height="200" @click="openImage(post.image)"></v-img>
                </div>

                <!-- Post content -->
                <v-card-text class="postText">
                    <p>{{ post.post }}</p>
                </v-card-text>
                <v-divider :thickness="2" class="border-opacity-20" color="white" width="100%"></v-divider>

                <!-- Post actions with date, edit, and delete buttons -->
                <v-card-actions class="d-flex align-center">
                    <!--<v-icon @click="likePost(post)">mdi-thumb-up</v-icon>
                    <v-chip class="ma-2" color="grey darken-1" text-color="white" label>
                         Likes
                    </v-chip>
                -->
                    <v-icon @click="post.showComments = !post.showComments" color="grey darken-1" text-color="white" label>
                        mdi-comment
                    </v-icon>
                    <v-chip class="ma-2" color="grey darken-1" text-color="white" label>
                        {{ post.comments.length }} Comments
                    </v-chip>

                    <span></span> <!-- This empty span is for alignment -->
                    <div class="d-flex align-center">
                        <v-btn icon small @click="editPost(post)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon small @click="deletePost(post)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </div>
                </v-card-actions>
                <v-divider :thickness="2" class="border-opacity-20" color="white" width="100%"></v-divider>

                <div class="d-flex flex-column align-start ">
                    <!-- toggle comments with a icon -->

                    <div v-if="post.showComments">
                        
                        <v-divider :thickness="2" class="border-opacity-20" color="white" width="100%"></v-divider>
                        <div v-for="comment in post.comments" :key="comment.id" class="commentMainCon">
                            <v-avatar class="mr-2" size="50">
                              <v-img v-if="!comment.userImage" src="./images/default.webp"></v-img>
                              <v-img v-else-if="comment.userImage" :src="comment.userImage"></v-img>
                            </v-avatar>
                            <div class="commentInsideCon" color="grey darken-1" text-color="white" label>
                              <div class="mr-2 d-flex flex-column commentCont"
                                style="max-width: 600px; white-space: normal; overflow-wrap: break-word; word-break: break-word;"
                                color="grey darken-1" text-color="white" label>
                                <div class="d-flex">
                                  <h3 class="mb-0">{{ comment.username }}</h3>
                                </div>
                                <span>{{ comment.comment }}</span>
                              </div>
            
                              <div class="d-flex align-center dateCom">
                                <v-icon>mdi-clock-time-four-outline</v-icon>
                                {{ formatDate(comment.date) }}
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
                <v-textarea label="Comment" v-model="comments[post._id]" rows="1" @keydown.enter="postComment(post._id)"></v-textarea>
                <!-- Comments -->
            </v-card>
        </div>
    </div>
</v-container>
<!--invisable image dialog when post image clicked it opens big image-->
<v-dialog v-model="imgdialog" max-width="1200">
    <v-img :src="dialogImage" contain></v-img>
</v-dialog>
<!--edit post dialog-->
<v-dialog v-model="editDialog" max-width="800">
    <v-card>
        <v-card-title>Edit Post</v-card-title>
        <v-card-text>
            <v-textarea v-model="editedPost.content" label="Post Content"></v-textarea>
        </v-card-text>
        <v-card-actions>
            <v-btn @click="editDialog = false">Cancel</v-btn>
            <v-btn color="primary" @click="savePost">Save</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<style scoped>
.commentCont {
    background-color: #424242;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    max-width: 300px;
    display: flex !important;
    flex-direction: column !important;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  
  .dateCom {
    margin-left: 2px;
    font-size: 10px;
  }
  
  .commentInsideCon {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    margin-left: 10px;
  }
  
  .commentMainCon {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px;
  }
  
.coverUpIcon {
    color: white;
    transition: color 0.3s;
    right: 20px;
    top: 5px;
    cursor: pointer !important;
}

.coverUpIcon:hover {
    color: #424242;
}

.ProfileDetails {
    margin-bottom: 20px;
    flex-direction: column !important;
}

.follower-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.follower-container:hover {
    background-color: #424242;
    border-radius: 10px;
}

.profileUpIcon {
    top: -43px;
    color: white;
    cursor: pointer;
    transition: color 0.3s;
}

.profileUpIcon:hover {
    color: #424242;
}

.postText {
    line-height: 1.6;
    color: white;
    font-size: 1.6rem;
}

.postTitle {
    line-height: 1.6;
    background-color: #424242;
    color: white;
}

.Tabs {

    line-height: 1.6;
    background-color: #212121;
    color: white;
}

.profile-container {
    position: relative;
    padding: 0;
}

.cover-photo {
    height: 200px;
    width: 100%;
    object-fit: cover;
}

.profile-info {
    position: relative;
    top: -60px;
    /* Adjust this value to overlap the profile picture on the cover photo */
    margin-bottom: -60px;
}

.profile-details {
    padding-left: 15px;
    margin-top: 80px;
    /* Adjust to position name and friend count properly */
}

.profile-name {
    margin: 0;
    font-weight: bold;
}

.profile-friends {
    margin: 0;
    color: gray;
    font-size: 14px;
}

.TabContent {
    width: 40%;
    height: 100%;
    padding: 10px;
}

.postArea {
    width: 60%;
    height: 100%;
    padding: 10px;
}

.ProfileContainer {
    display: flex;
    padding: 10px;
    margin-top: 10px;
}

.postCard {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    background-color: #212121;
    color: white;
}

.postImg {
    border-radius: 10px;

}

.track-container {
    margin-bottom: 20px;
}

.track-player {
    display: flex;
    align-items: flex-start;
    background-color: #2b2a2a;
}

.track-image {
    width: 150px;
    height: 150px;
    margin-right: 15px;
    object-fit: cover;
}

.player-container {
    flex-grow: 1;
}

.top-section {
    display: flex;
    align-items: center;
}

.play-button {
    margin-right: 10px;
    cursor: pointer;
}

.trackActions {
    margin-right: 10px;
    cursor: pointer;
    background-color: #2b2a2a;
}

.track-title {
    margin: 0;
}

.waveform-container {
    margin-top: 10px;
    height: 60px;
    background-color: #42424200;
    border-radius: 4px;
    position: relative;
}

.track-actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}
</style>

<script>
import authService from '../services/authService';
import WaveSurfer from 'wavesurfer.js'
export default {
    name: 'MyProfile',
    data() {
        return {
            userdata: {
                username: '',
                bio: '',
                age: '',
                location: '',
                socialLinks: {
                    facebook: '',
                    twitter: '',
                    instagram: ''
                },

            },
            comments: {},
            uploadtrack: false,
            newTrack: {
                title: '',
                file: null
            },
            playingTrackId: null,
            editDialog: false,
            postText: '',
            imgdialog: false,
            dialogImage: '',
            activeTab: 0,
            showFileInput: false,
            profilePicture: null,
            followers: [],
            following: [],
            UserPosts: [],
            editedPost: {
                id: '',
                content: ''
            },
            editProfileDialog: false,
            wavesurfer: null,
            waveSurferInstances: {},
            confirmImageUpload: false,
            uploadingImage: false,
            tracks: [{
                    id: 1,
                    title: 'Track 1',
                    url: '/audio/track1.mp3',
                    date: '2021-09-01'
                },
                {
                    id: 2,
                    title: 'Track 2',
                    url: '/audio/track2.mp3',
                    date: '2021-09-02'
                },
                {
                    id: 3,
                    title: 'Track 3',
                    url: '/audio/track3.mp3',
                    date: '2021-09-03'
                }
            ]

        };
    },
    methods: {
        uploadTrack() {
            if (this.$refs.form.validate()) {
                // Handle track upload
                const formData = new FormData();
                formData.append('title', this.newTrack.title);
                formData.append('file', this.newTrack.file);

                // Example: Upload logic using fetch or axios
                // axios.post('/upload-track', formData)
                //   .then(response => {
                //     // Handle successful upload
                //     this.dialog = false;
                //     this.newTrack = { title: '', file: null };
                //     this.tracks.push(response.data);
                //   })
                //   .catch(error => {
                //     // Handle upload error
                //   });
            }
        },
        playTrack(trackId) {
            const wavesurfer = this.waveSurferInstances[trackId];
            if (wavesurfer) {
                if (this.playingTrackId === trackId) {
                    // If this track is already playing, pause it
                    wavesurfer.pause();
                    this.playingTrackId = null;
                } else {
                    // If another track is playing, pause it
                    if (this.playingTrackId) {
                        this.waveSurferInstances[this.playingTrackId].pause();
                    }
                    // Play the selected track
                    wavesurfer.play();
                    this.playingTrackId = trackId;
                }
            }
        },
        isPlaying(trackId) {
            return this.playingTrackId === trackId;
        },
        preloadtracks() {
            console.log('preloadtracks method called');
            console.log('Tracks:', this.tracks);
            this.$nextTick(() => {
                this.tracks.forEach(track => {
                    console.log(`Attempting to create waveform for track ${track.id}`);
                    const container = this.$refs[`waveform-${track.id}`];
                    if (container && container[0]) {
                        console.log(`Container found for track ${track.id}`);
                        const wavesurfer = WaveSurfer.create({
                            container: container[0],
                            waveColor: 'white',
                            progressColor: 'grey',
                            barWidth: 1,
                            barHeight: 1,
                            responsive: true,
                            cursorWidth: 1,
                            backend: 'WebAudio',
                            height: 60
                        });
                        wavesurfer.load(track.url);
                        wavesurfer.on('error', (err) => {
                            console.error(`Error loading track ${track.id}:`, err);
                        });
                        wavesurfer.on('ready', () => {
                            console.log(`Waveform ready for track ${track.id}`);
                        });
                        this.waveSurferInstances[track.id] = wavesurfer;
                    } else {
                        console.error(`Container not found for track ${track.id}`);
                    }
                });
            });
        },

        uploadProfilePicture() {
            // Implement the logic to upload a new profile picture
            this.$refs.fileInput.click();
        },
        uploadCoverPhoto() {
            // Implement the logic to upload a new cover photo
            this.$refs.coverInput.click();
        },
        async handleCoverUpload(event) {
            const file = event.target.files[0];
            if (file) {
                //chack if the file is an image file and less than 5mb in size
                if (file.type.startsWith('image/') && file.size <= 5000000) {
                    this.uploadingImage = true;
                    try {
                        console.log('Uploading image:', file);

                        const response = await authService.uploadCoverPhoto(file, this.userdata._id);
                        if (response.status === 200) {
                            this.uploadingImage = false;
                            //get the new user data
                            const newuserdata = await authService.getUserData(this.userdata._id);
                            this.userdata = newuserdata.data;

                        }

                    } catch (error) {
                        console.error(error);
                    }

                } else {
                    alert('Please upload an image file less than 5MB');
                }
            }
        },
        async updateProfile() {
            try {
                const response = await authService.updateProfile(this.userdata._id, this.userdata.bio, this.userdata.age, this.userdata.location, this.userdata.sex, this.userdata.interests);
                if (response.status === 200) {
                    this.editProfileDialog = false;
                    //update the userdata
                    this.userdata = response.data.userdata;
                }
            } catch (error) {
                console.error(error);
            }
        },
        async handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                //chack if the file is an image file and less than 5mb in size
                if (file.type.startsWith('image/') && file.size <= 5000000) {
                    this.uploadingImage = true;
                    try {
                        console.log('Uploading image:', file);

                        const response = await authService.uploadProfilePicture(file, this.userdata._id);
                        if (response.status === 200) {
                            this.uploadingImage = false;
                            this.profilePicture = response.data.profileimage;
                        }

                    } catch (error) {
                        console.error(error);
                    }

                } else {
                    alert('Please upload an image file less than 5MB');
                }
            }

        },
        editPost(post) {
            this.editedPost = {
                id: post._id,
                content: post.post
            };
            this.editDialog = true;
        },
        async postComment(postId) {
            try {
                await authService.commentPost(postId, this.comments[postId], this.userdata._id, this.userdata.profileimage, this.userdata.username);
                this.comments[postId] = '';
                this.getuserposts();
            } catch (error) {
                console.error(error);
            }
        },
        async updatePost() {
            try {
                await authService.updatePost(this.editedPost.id, this.editedPost.content);
                this.editDialog = false;
                this.getuserposts();
            } catch (error) {
                console.error(error);
            }
        },
        async deletePost(post) {
            try {
                console.log('Deleting post:', post);
                const id = post._id;
                await authService.deletePost(id);
                this.getuserposts();
            } catch (error) {
                console.error(error);
            }

        },
        formatDate(date) {
            // Implement the logic to format the date
            return new Date(date).toLocaleString();
        },
        openImage(image) {
            this.dialogImage = image;
            this.imgdialog = true;
        },
        savePost() {
            // Implement the logic to save the edited post
            this.updatePost();
            this.editDialog = false;
        },
        editProfile() {
            // Implement the logic to edit the user profile
            console.log('Editing profile...');
        },
        switchTab(tabIndex) {
            this.activeTab = tabIndex;
        },
        async postContent() {
            console.log('Posting content:', this.postText, this.userdata._id);
            if (this.postText) {
                try {
                    await authService.postContent(this.postText, this.userdata._id);
                    this.postText = '';
                } catch (error) {
                    console.error(error);
                }

            }
            //update the posts
            this.getuserposts();
        },
        async getuserposts() {
            try {
                const response = await authService.getUserPosts(this.userdata._id);

                // Update the UserPosts array with the response data by date newest first
                this.UserPosts = response.data;
                //reverse the array to show the newest post first
                this.UserPosts.reverse();
            } catch (error) {
                console.error(error);
            }
        },
        async getfollowers() {
            try {
                const response = await authService.getfollowers(this.userdata._id);
                if (response.data !== null) {
                    this.followers = response.data;

                } else {
                    this.followers = {};
                }

                console.log('Followers:', this.followers);

            } catch (error) {
                console.error(error);
            }
        },
        async getfollowing() {
            try {
                const response = await authService.getfollowing(this.userdata._id);
                if (response.data !== null) {
                    this.following = response.data;
                } else {
                    this.following = [];
                }
                console.log('Following:', this.following);
            } catch (error) {
                console.error(error);
            }
        },

        async viewProfile(username) {

            this.$router.push(`/user/${username}`);
        },

    },
    async mounted() {

        const userid = localStorage.getItem('user-id');
        try {
            const response = await authService.getUserData(userid);
            this.userdata = response.data;
            //check if userdata in localstorage exists and overwrite it
            if (localStorage.getItem('userdata')) {
                localStorage.removeItem('userdata');
            }
            localStorage.setItem('userdata', JSON.stringify(response.data));
            this.profilePicture = response.data.profileimage;
            this.getuserposts();
            this.getfollowers();
            this.getfollowing();
        } catch (error) {
            console.error(error);
        }

    },
    watch: {
        activeTab(newTab) {
            if (newTab === 3) {
                this.$nextTick(() => {
                    this.preloadtracks();
                });
            }
        }
    },

};
</script>

<style scoped>
.v-avatar {
    margin: 0 auto;
    display: block;
    width: 100%;
}

.v-btn {
    margin-top: 10px;
}
</style>
