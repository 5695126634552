<template>
    <router-view></router-view>
  </template>
  
  <script>
  export default {
    name: 'App',
  };
  </script>
  
  <style>
  /* Add global styles here if needed */
  body {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    margin: 0;
    padding: 0;
    background-color: #191B1A;
  }
  </style>
  